import { makeAutoObservable, configure, has } from "mobx";
import { fetchAdminCompanies, fetchCompanyDetails, fetchUser, postLoginCode, postLoginRequest } from "./api";

configure({
  enforceActions: "never",
})

class AdminStore {
  constructor(toaster, auth){
    makeAutoObservable(this, {}, { autoBind: true })
    this.toaster = toaster
    this.auth = auth
  }

  companies = {
    loading: false,
    loaded: false,
    hasMore: true,
    page: 0,
    searchQuery: '',
    content: []
  }

  setSearchTerm(term){
    this.companies.searchQuery = term
    if(term === ''){
      this.getCompanies(true)
    }else{
      this.getCompanies(false, false, false, true)
    }
  }

  async getCompanies(init = false, next = false, prev = false, search = false){
    try{
      if(init){
        this.companies.page = 0
        this.companies.searchQuery = ''
        this.companies.hasMore = true
        this.companies.content = []
      }
      if(search){
        this.companies.page = 0
        this.companies.hasMore = true
        this.companies.content = []
      }
      if(!this.companies.hasMore && !prev){
        return
      }
      this.companies.loading = true
      if(next){
        this.companies.page += 1
      }
      if(prev && this.companies.page !== 0){
        this.companies.page -= 1
      }
      const { companies, hasMore } = await fetchAdminCompanies(this.companies.page, this.companies.searchQuery)
      this.companies.content = [...companies]
      this.companies.loaded = true
      this.companies.hasMore = hasMore
    }catch(err){
      console.log(err)
    }finally{
      this.companies.loading = false
    }
  }

}

export default AdminStore