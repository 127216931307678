import { MobXProviderContext, observer } from "mobx-react";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns'
import { ReactComponent as SearchIcon } from '../../svg/search.svg';
import DateFilter from "../Partials/DateFilter";
import { useNavigate } from 'react-router-dom';
import Pagination from "../Partials/Pagination";

let _debounce = null;
const DraftOrders = observer(() => {

  const [closeFilters, setCloseFilters] = useState(false)
  const [dateStart, setDateStart] = useState(null)
  const [dateEnd, setDateEnd] = useState(null)
  const [confirmTarget, setConfirmTarget] = useState(false)
  const [filterQuery, setFilterQuery] = useState('')
  const { t } = useTranslation()

  const { order: { 
    getDraftOrders,
    handleDraftFilterChange,
    draftOrders: { loaded, loading, hasMore, content, page }, 
    deleteDraftOrder 
  }, auth: { companyLoaded } } = useContext(MobXProviderContext)

  useEffect(() => {
    if(companyLoaded){
      getDraftOrders(true)
    }
  }, [companyLoaded])

  const shouldCloseFilters = e => {
    setCloseFilters(!closeFilters)
  }

  const navigate = useNavigate()

  const resetCalendar = () => {
    setDateStart(null)
    setDateEnd(null)
    handleDraftFilterChange(null, null, filterQuery)
  }

  const handleCalendarChange = (start, end) => {
    setDateStart(start)
    setDateEnd(end)
    handleDraftFilterChange(start, end, filterQuery)
  }

  const handleSearchChange = e => {
    const { value } = e.target
    setFilterQuery(value)
    clearTimeout(_debounce)
    _debounce = setTimeout(() => {
      console.log('here')
      handleDraftFilterChange(dateStart, dateEnd, value)
    }, 750)
  }

  const editOrder = id => {
    navigate(`/order/create/${id}`)
  }
  
  const handlePaginate = next => {
    getDraftOrders(false, false, next)
    document.body.scroll(0, 0)
  }

  const deleteOrder = async id => {
    try{
      await deleteDraftOrder(id)
      setConfirmTarget(null)
    }catch(err){
      console.log(err)
    }
  }

  return (
    <div className="orders orders-drafts w-100" onClick={e => shouldCloseFilters(e)}>
      <div className="orders_header">
        <div className="row g-0">
          <div className="col-lg-8 col-12">
            <div className="orders_header-content">
              <DateFilter 
                onChange={(startDate, endDate) => handleCalendarChange(startDate, endDate)} 
                separator={<span className="filter-separator contrast-md-text">{t("until")}</span>} 
                closeFilters={closeFilters}
                reset={() => resetCalendar()}
              />
            </div>
          </div>
          <div className="col-lg-4 col-12">
            <div className="orders_header-content search-form">
              <div className="form-group">
                <SearchIcon />
                <input onChange={e => handleSearchChange(e)} value={filterQuery} className="form-control" type="text" name="searchQuery" placeholder={t("searchByName")} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="order_body">
        <div className="order-table">
          <div className="order-table_header">
            <div className="row g-0">
              <div className="col-lg-4 order-table_header-left">
                <label>{t("orderName")}</label>
              </div>
              <div className="col-lg-4">
                <label>{t("date")}</label>
              </div>
              <div className="col-lg-4">
                <label>{t("actions")}</label>
              </div>
            </div>
          </div>
          <div className="order-table_body">
            {
              !loaded || loading ? (
                <div>
                  <span> loading</span>
                </div>
              )
              :
              (
                content.length === 0 ? (
                  <div className="row g-0 order-table_body-row">
                    <div className="col-12 order-table_body-row-empty">
                      <label className="text-100 md-text">
                        {t("noHistory")}
                      </label>
                    </div>
                  </div>
                )
                :
                (
                  content.map(({ _id, updatedAt, name }) => {
                    return (
                      <div key={_id} className="row g-0 order-table_body-row">
                        <div className="col-lg-4 order-table_body-left">
                          <label className="text-100 md-text">{name}</label>
                        </div>
                        <div className="col-lg-4">
                          <label className="text-100 md-text">{format(new Date(updatedAt), 'dd.MM.yyyy')}</label>
                        </div>
                        <div className="col-lg-4">
                          <div className="order-table_body-actions">
                            {
                                confirmTarget && _id === confirmTarget ? (
                                  <>
                                    <button onClick={() => setConfirmTarget(null)} className="btn-none btn-0 p-0 underline md-text text-100 me-2">
                                      {t("dismiss")}
                                    </button>
                                    <button onClick={() => deleteOrder(confirmTarget)} className="btn-none btn-0 p-0 underline md-text text-100">
                                      {t("confirm")}
                                    </button>
                                  </>
                                )
                                :
                                (
                                  <>
                                    <button onClick={() => editOrder(_id)} className="btn-none btn-0 p-0 underline md-text text-100">
                                      {t("changeOrConfirm")}
                                    </button>
                                    <button onClick={() => setConfirmTarget(_id)} className="btn-none btn-0 p-0 ms-3 underline md-text text-100">
                                      {t("delete")}
                                    </button>
                                  </>
                                )
                            }
                          </div>
                        </div>
                      </div>
                    )
                  })
                )
              )
            }
          </div>
        </div>
      </div>
      <div className="order_pagination">
          <Pagination handleClick={next => handlePaginate(next)} disableNext={!hasMore} disablePrevious={page === 0} />
      </div>
    </div>
  )

})

export default DraftOrders