import { observer } from "mobx-react"
import { Link } from "react-router-dom"

const Banner = observer(({ title, subtitle, icon, buttonText, buttonAction, className }) => {

  return (
    <div className={`banner ${className ? className : 'default'}`}>
      <div className="row g-0">
        <div className="col-12">
          <div className="col-8">
            <div className="d-flex flex-column">
              <div className="h-100 d-flex pb-2 align-items-center me-3">
                <div className="banner_icon">
                  {icon}
                </div>
              </div>
              <div className="d-flex pb-2 flex-column me-2">
                <h1 className="banner_title mb-1">{title}</h1>
                <p className="banner_subtitle">{subtitle}</p>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="d-flex flex-column h-100 justify-content-center">
              <Link to={'/register'} className="btn-light btn-slim">
                {buttonText}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

})

export default Banner