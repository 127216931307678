import { MobXProviderContext, observer } from "mobx-react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as File } from '../../svg/file-attachment.svg';
import { ReactComponent as FileCheck } from '../../svg/file-check-light.svg';
import { ReactComponent as GridIcon } from '../../svg/grid.svg'
import { format } from 'date-fns';
import { useContext, useEffect } from "react";
import SectionTitle from "../Partials/SectionTitle";
import LoadingScreen from "../Partials/LoadingScreen";

const Order = observer(() => {

  const { t } = useTranslation()

  const { order: { getOrderDetails, selectedOrder, selectedOrderLoading, getOrderSalesDocumentHTML, salesDocLoading }, auth: { companyLoaded, showTaxRow } } = useContext(MobXProviderContext)

  const { id } = useParams()

  useEffect(() => {
    if((!selectedOrder && !selectedOrderLoading) || (selectedOrder && selectedOrder.order && selectedOrder.order._id !== id)){
      getOrderDetails(id)
    }
  }, [])

  if((selectedOrderLoading || !selectedOrder) || (selectedOrder && selectedOrder.order && selectedOrder.order._id !== id)){
    return (
      <div className="order-details">
        <LoadingScreen />
      </div>
    )
  }
  const { salesDocuments, erplyOrder, order } = selectedOrder


  const getOrderTotalSum = (tax = false, taxOnly = false, ignoreShipping = false) => {
    const { shippingCost, shippingMethod, products: orderProducts } = selectedOrder.order
    let orderSum = 0
    let orderTaxSum = 0
    orderProducts.forEach(({ productID, amount, priceListPrice, priceListPriceWithVat }) => {
      const totalPrice = Number(priceListPrice) * amount
      const totalPriceVAT = Number(priceListPriceWithVat) * amount
      if(!productID){
        orderSum += 0
      }else{
        orderSum += tax ? totalPriceVAT : totalPrice
        orderTaxSum += (totalPriceVAT - totalPrice)
      }
    })
    if(shippingMethod !== 'pickup' && !ignoreShipping){
      orderTaxSum += (shippingCost * 1.2 - shippingCost)
      orderSum += (shippingCost * (showTaxRow ? 1.2 : 1))
    }
    if(taxOnly){
      return orderTaxSum.toFixed(2)
    }
    return orderSum.toFixed(2)
  }

  const getOrderSummary = () => {
    const { shippingCost, shippingMethod } = selectedOrder.order
    return (
      <div className="summary-card card">
        <div className="card_body">
          <div className="row g-0 summary-card_row">
            <div className="col-6 summary-card_row-content">
              <label>{t('sum')}</label>
            </div>
            <div className="col-5 summary-card_row-content">
              <label>€{getOrderTotalSum(false, false, true)}</label>
            </div>
          </div>
          {
            shippingMethod ? (
              <div className="row g-0 summary-card_row">
                <div className="col-6 summary-card_row-content">
                  <label>{t(shippingMethod)}</label>
                </div>
                <div className="col-5 summary-card_row-content">
                  <label>€{shippingCost.toFixed(2)}</label>
                </div>
              </div>
            )
            :
            undefined
          }
          {
            showTaxRow ? 
            (
            <div className="row g-0 summary-card_row">
              <div className="col-6 summary-card_row-content">
                <label>{t('VAT')}</label>
              </div>
              <div className="col-5 summary-card_row-content">
                <label>€{getOrderTotalSum(false, true)}</label>
              </div>
            </div>
            )
            :
            undefined
          }
        </div>
        <div className="card_footer">
          <div className="row g-0 summary-card_row">
            <div className="col-6 summary-card_row-content">
              <label className="text-100">{t('orderTotal')}</label>
            </div>
            <div className="col-5 summary-card_row-content">
              <label className="text-100">€{getOrderTotalSum(showTaxRow)}</label>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="order-details">
      <DetailsHeader erplyOrder={erplyOrder} order={order} />
      <div className="order-details_body">
        <SectionTitle titleKey={"invoices"} icon={<File />} />
        <div className="order-invoices">
          <div className="order-invoices_header">
            <div className="row g-0">
              <div className="col order-table_header-left">
                <label>{t("invoiceNo")}</label>
              </div>
              <div className="col">
                <label>{t("date")}</label>
              </div>
              <div className="col">
                <label>{t("grossSum")}</label>
              </div>
              <div className="col">
                <label>{t("status")}</label>
              </div>
              <div className="col">
                <label>{t("invoice")}</label>
              </div>
            </div>
          </div>
          <div className="order-invoices_body">
            <InvoiceTableBody getOrderSalesDocumentHTML={getOrderSalesDocumentHTML} salesDocuments={salesDocuments} erplyOrder={erplyOrder} order={order} />
          </div>
        </div>
        <div className="order-details_body-separator" />
        <SectionTitle titleKey={"orderOverview"} icon={<GridIcon />} />
        <OverviewTable erplyOrder={erplyOrder} order={order} showTaxRow={showTaxRow} />
        <div className="order-details_body-separator" />
        <div className="row g-0">
          <div className="col-md-12 col-lg-4 offset-lg-8">
            <div style={{ minWidth: '100%' }}>
              <SectionTitle titleKey={"orderSummary"} icon={<FileCheck />} />
              {getOrderSummary()}
            </div>
          </div>
        </div>
      </div>
    </div>
  )

})

export default Order

const OverviewTable = ({ erplyOrder, order, showTaxRow }) => {
  const { t } = useTranslation()

  return (
    <div className="order-overview">
      <div className="order-overview_header row g-0">
        <div className="col order-overview_header-left">
          <label>{t('productName')}</label>
        </div>
        <div className="col">
          <label>SKU</label>
        </div>
        <div className="col">
          <label>{t('price')}</label>
        </div>
        {
          showTaxRow ? (
            <div className="col">
              <label>
                {t('priceVAT')}
              </label>
            </div>
          )
          :
          undefined
        }
        <div className="col">
          <label>{t('amount')}</label>
        </div>
        <div className="col">
          <label>{t('total')}</label>
        </div>
      </div>
      <div className="order-overview_body">
        {
          order.products.map(product => {
            return (
              <div key={product.productID} className="row g-0 order-overview_body-row">
                <div className="col col order-overview_body-left">
                  <label className="text-md text-82 mobile-label">{t('productName')}</label>
                  <label className="text-md text-82">{product.name}</label>
                </div>
                <div className="col">
                  <label className="text-md text-82 mobile-label">SKU</label>
                  <label>{product.code}</label>
                </div>
                {
                  showTaxRow ? (
                    <div className="col">
                    <label className="text-md text-82 mobile-label">{t('priceVAT')}</label>
                      <label>€{Number(product.priceListPrice).toFixed(2)}</label>
                    </div>
                  ) : 
                  undefined
                }
                <div className="col">
                  <label className="text-md text-82 mobile-label">{t('price')}</label>
                  <label>€{Number(product.priceListPriceWithVat).toFixed(2)}</label>
                </div>
                <div className="col">
                  <label className="text-md text-82 mobile-label">{t('amount')}</label>
                  <label>{product.amount}</label>
                </div>
                <div className="col">
                  <label className="text-md text-82 mobile-label">{t('total')}</label>
                  <label>€{showTaxRow ? product.amount * Number(product.priceListPriceWithVat) : product.amount * Number(product.priceListPrice)}</label>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

const InvoiceTableBody = ({ getOrderSalesDocumentHTML, salesDocuments }) => {

  const { t } = useTranslation()

  return (
    (!salesDocuments || salesDocuments.length === 0) ?
    (
      <div className="empty-table">
        <span>{t("orderBeingConfirmed")}</span>
      </div>
    )
    :
    (
      salesDocuments.map(document => {
        return (
          <div key={document.id} className="row g-0 order-invoices_body-row">
            <div className="col order-invoices_body-left">
              <label className="text-md text-82 mobile-label">{t('invoiceNo')}</label>
              <label className="text-87 md-text">#{document.number}</label>
            </div>
            <div className="col">
              <label className="text-md text-82 mobile-label">{t('date')}</label>
              <label className="text-87 md-text">{format(new Date(document.date), 'dd.MM.yyyy')}</label>
            </div>
            <div className="col">
              <label className="text-md text-82 mobile-label">{t('total')}</label>
              <label className="text-87 md-text">€{document.paid}</label>
            </div>
            <div className="col">
              <label className="text-md text-82 mobile-label">{t('status')}</label>
              <label className="text-87 md-text">{t(document?.paymentStatus)}</label>
            </div>
            <div className="col">
              <label className="text-md text-82 mobile-label">{t('invoice')}</label>
              <button onClick={() => getOrderSalesDocumentHTML(document.id, document.number)} className="btn-none btn-0 p-0 underline text-87 md-text">
                {t("downloadPDF")}
              </button>
            </div>
          </div>
        )
      })
    )
  )

}

const DetailsHeader = ({ order, erplyOrder }) => {

  const { t } = useTranslation()

  const { updatedAt, createdBy: { firstName, lastName } } = order

  return (
    <div className="order-details_header">
      <div className="row g-0">
        <div className="col-lg-1 col-md-2 col-6">
          <label className="text-87 contrast-lg-text">{t("date")}</label>
        </div>
        <div className="col-10">
          <label className="text-100 lg-text">{format(new Date(updatedAt), 'dd.MM.yyyy')}</label>
        </div>
      </div>
      <div className="row g-0">
        <div className="col-lg-1 col-md-2 col-6">
          <label className="text-87 contrast-lg-text">{t("status")}</label>
        </div>
        <div className="col-10">
          <label className="text-100 lg-text">{t(erplyOrder?.paymentStatus)}</label>
        </div>
      </div>
      <div className="row g-0">
        <div className="col-lg-1 col-md-2 col-6">
          <label className="text-87 contrast-lg-text">{t("createdBy")}</label>
        </div>
        <div className="col-10">
          <label className="text-100 lg-text">{firstName} {lastName}</label>
        </div>
      </div>
    </div>
  )
}