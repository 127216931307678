import { MobXProviderContext, observer } from "mobx-react";
import { useContext, useEffect, useState } from "react";
import { fetchadminNotification, postAdminNotification, putAdminNotification } from "../../Store/api";
import { ReactComponent as AnnotationIcon } from '../../svg/annotation-main.svg'
import { ReactComponent as TrashIcon } from '../../svg/trash.svg'
import { ReactComponent as PlusIcon } from '../../svg/plus-circle.svg'
import SectionTitle from "../Partials/SectionTitle";
import Spinner from "../Partials/Spinner";

const AdminNotification = observer(() => {

  const { admin } = useContext(MobXProviderContext)

  const [loading, setLoading] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const [saved, setSaved] = useState('')
  const [newClicked, setNewClicked] = useState(false)
  const [existing, setExisting] = useState(null)
  const [existingID, setExistingID] = useState(null)

  const [fields, setFields] = useState({
    titleET: {
      label: 'Pealkiri ET',
      name: 'titleET',
      value: '',
    },
    messageET: {
      label: 'Sisu ET',
      name: 'messageET',
      value: '',
    },
    titleLV: {
      label: 'Pealkiri LV',
      name: 'titleLV',
      value: '',
    },
    messageLV: {
      label: 'Sisu LV',
      name: 'messageLV',
      value: '',
    },
    titleFI: {
      label: 'Pealkiri FI',
      name: 'titleFI',
      value: '',
    },
    messageFI: {
      label: 'Sisu FI',
      name: 'messageFI',
      value: '',
    },
  })

  useEffect(() => {
    if(!loaded){
      getNotification()
    }
  }, [])

  const getNotification = async () => {
    if(!loading && !loaded){
      try{
        setLoading(true)
        const { notification } = await fetchadminNotification()
        if(notification && notification._id){
          syncNotification(notification)
        }
        setLoaded(true)
      }catch(err){
        console.log(err)
      }finally{
        setLoading(false)
      }

    }
  }

  const syncNotification = (notification) => {
    const copy = { ...fields }
    Object.keys(notification).forEach(notificationKey => {
      if(copy[notificationKey]){
        copy[notificationKey].value = notification[notificationKey]
      }
    })
    setFields(copy)
    setExisting(notification)
    setExistingID(notification._id)
  }

  const initNotification = () => {
    const copy = { ...fields }
    Object.keys(fields).forEach(notificationKey => {
      if(copy[notificationKey]){
        copy[notificationKey].value = ''
      }
    })
    setFields(copy)
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    const copy = {...fields}
    copy[name].value = value
    setFields(copy)
  }

  const handleSave = async () => {
    try{
      setSaveLoading(true)
      if(existing && existing._id){
        const body = getNotificationBody()
        const result = await putAdminNotification(body, existing._id)
      }else{
        const body = getNotificationBody()
        const { notification } = await postAdminNotification(body)
        syncNotification(notification)
      }
    }catch(err){
      console.log(err)
    }finally{
      setSaveLoading(false)
    }
  }

  const deleteNotification = async () => {
    try{
      if(existing){
        const result = await putAdminNotification({ deleteRequest: true }, existing._id)
        if(result.deleted){
          setExisting(null)
          setExistingID(null)
          initNotification()
        }
      }else{
        setExisting(null)
        setExistingID(null)
        initNotification()
      }
    }catch(err){
      console.log(err)
    }
  }

  const getNotificationForm = () => {
    const keys = Object.keys(fields)
    return (
      <div className="admin_notification-body-form">
        <div className="admin_notification-body-form-fields">
          <div className="row g-0 ps-2">
            {
              keys.map(key => {
                const { label, name, value } = fields[key]
                return (
                  <div key={name} className="col-lg-6 col-md-6 col-12">
                    <div className="form-group pe-3">
                      <label>{label}</label>
                      <input type="text" onChange={e => handleInputChange(e)} name={name} className="form-control" value={value} />
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className="admin_notification-body-form-footer">
          {
            saveLoading ? (
              <div className="loader-wrapper">
                <Spinner className="small" />
              </div>
            )
            :
            (
              <>
                <button onClick={() => handleSave()} className="btn-dark">
                  Salvesta
                </button>
                {
                  existing ? (<button onClick={() => deleteNotification()} className="btn-none delete-btn btn-0 gray-btn">Kustuta <TrashIcon /></button>) : undefined
                }
              </>
            )
          }
        </div>
      </div>
    )
  }

  const getNotificationBody = () => {
    const notificationBody = {}
    Object.keys(fields).forEach(key => {
      notificationBody[key] = fields[key].value
    })
    if(existing){
      notificationBody._id = existing._id
    }
    return notificationBody
  }

  const createNotification = () => {
    setNewClicked(true)
  }

  console.log(existingID, newClicked)

  return (
    <div className="admin">
      <div className="admin_body">
        <div className="admin_notification">
          <div className="admin_notification-header">
            <SectionTitle icon={<AnnotationIcon />} className={"light"} titleKey={"adminAddNotification"} />
          </div>
          <div className="admin_notification-body">
            {
              !loaded || loading ? (
                <div />
              )
              :
              (
                existingID || newClicked ? (
                  getNotificationForm()
                )
                :
                (
                  <div className="admin_notification-body-add">
                    <button onClick={() => createNotification()} className="btn-none main-btn">
                      Lisa teavitus
                      <PlusIcon />
                    </button>
                  </div>
                )
              )
            }
          </div>
        </div>
      </div>
    </div>
  )

})

export default AdminNotification