import { makeAutoObservable, configure } from "mobx";
import { deleteDraft, postOrderDuplicate, fetchDraftOrders, fetchHistoricalOrders, fetchOrderDetails, postDraftOrder, postErplyOrder, fetchSalesDocumentHTML } from "./api";
import jsPDF from 'jspdf';

configure({
  enforceActions: "never",
})

class OrderStore {
  constructor(toaster, auth){
    makeAutoObservable(this, {}, { autoBind: true })
    this.toaster = toaster
    this.auth = auth
  }

  orderHistory = {
    loaded: false,
    page: 0,
    loading: false,
    hasMore: true,
    filterQuery: '',
    dateStart: null,
    dateEnd: null,
    content: [] 
  }

  draftOrders = {
    loaded: false,
    page: 0,
    loading: false,
    hasMore: true,
    filterQuery: '',
    dateStart: null,
    dateEnd: null,
    content: []
  }
  draftSaveLoading = false
  createLoading = false
  duplicateLoading = false
  salesDocLoading = false

  selectedOrder = null
  selectedOrderLoading = false

  async createErplyOrder(products, name, shippingAddress, shippingMethod, selfPickup, draftOrderID = null){
    try{
      this.createLoading = true
      const body = {
        products,
        name,
        shippingMethod,
        company: this.auth.company._id,
        shippingAddress,
        selfPickup,
        draftOrderID
      }
      const { order } = await postErplyOrder(body)
      return order._id
    }catch(err){
      console.log(err)
    }finally{
      this.createLoading = false
    }
  }

  async duplicateOrder(orderID){
    try{
      if(this.duplicateLoading){
        return
      }
      this.duplicateLoading = true
      const { order } = await postOrderDuplicate({ orderID }, this.auth.lang)
      if(order){
        return {
          draftID: order._id,
          success: true
        }
      }
    }catch(err){
      console.log(err)
      return {
        success: false,
        message: err.response.data.message
      }
    }finally{
      this.duplicateLoading = false
    }
  }

  async getOrderSalesDocumentHTML(id, docNumber){
    try{
      if(this.salesDocLoading){
        return
      }
      this.salesDocLoading = true
      const docIndex = this.selectedOrder.salesDocuments.findIndex(({ number }) => number === docNumber)
      const { number } = this.selectedOrder.salesDocuments[docIndex]
      await fetchSalesDocumentHTML(id, number, this.auth.lang)
    }catch(err){
      console.log(err)
    }finally{
      this.salesDocLoading = false
    }
  }

  async getOrderDetails(id) {
    try{
      if(this.selectedOrder && this.selectedOrder._id === id){
        return
      }
      if(this.selectedOrderLoading){
        return
      }
      this.selectedOrderLoading = true
      const { order, erplyOrder, salesDocuments } = await fetchOrderDetails(id)
      this.selectedOrder = {
        order,
        erplyOrder,
        salesDocuments
      }
    }catch(err){
      console.log(err)
    }finally{
      this.selectedOrderLoading = false
    }
  }


  handleHistoricalFilterChange(dateStart = null, dateEnd = null, filterQuery = ''){
    this.orderHistory.filterQuery = filterQuery
    this.orderHistory.dateStart = dateStart
    this.orderHistory.dateEnd = dateEnd
    if(filterQuery === '' && dateStart === null && dateEnd === null){
      this.getHistoricalOrders(true)
    }else{
      this.getHistoricalOrders(false, false, false, true)
    }
  }

  async getHistoricalOrders(init = false, refresh = false, next = true, filterChange = false){
    try{
      if(init){
        this.orderHistory.page = 0
        this.orderHistory.hasMore = true
        this.orderHistory.loaded = false
        this.orderHistory.dateEnd = null
        this.orderHistory.dateStart = null
        this.orderHistory.filterQuery = ''
      }
      if(filterChange){
        this.orderHistory.page = 0
        this.orderHistory.hasMore = true
      }
      if(!next && this.orderHistory.page === 0 && !filterChange){
        return
      }
      if(next && !this.orderHistory.hasMore && !filterChange){
        return
      }
      if(this.orderHistory.loading){
        return
      }
      this.orderHistory.loading = true
      if(!next && this.orderHistory.page > 0 && !refresh && !filterChange){
        this.orderHistory.page -= 1
      }
      if(!init && !refresh && next && this.orderHistory.hasMore && !filterChange){
        this.orderHistory.page += 1
      }
      const filterArray = []
      const keys = ['filterQuery', 'dateStart', 'dateEnd']
      keys.forEach(key => {
        if(this.orderHistory[key]){
          filterArray.push({ key, value: this.orderHistory[key] })
        }
      })
      const { orders, hasMore } = await fetchHistoricalOrders(this.orderHistory.page, this.auth.company._id, filterArray)
      this.orderHistory.hasMore = hasMore
      this.orderHistory.content = orders
      this.orderHistory.loaded = true
    }catch(err){
      console.log(err)
    }finally{
      this.orderHistory.loading = false
    }
  }

  handleDraftFilterChange(dateStart = null, dateEnd = null, filterQuery = ''){
    this.draftOrders.filterQuery = filterQuery
    this.draftOrders.dateStart = dateStart
    this.draftOrders.dateEnd = dateEnd
    if(filterQuery === '' && dateStart === null && dateEnd === null){
      this.getDraftOrders(true)
    }else{
      this.getDraftOrders(false, false, false, true)
    }
  }

  async getDraftOrders(init = false, refresh = false, next = true, filterChange = false){
    try{
      if(init){
        this.draftOrders.page = 0
        this.draftOrders.hasMore = true
        this.draftOrders.loaded = false
        this.draftOrders.dateStart = null
        this.draftOrders.dateEnd = null
        this.draftOrders.filterQuery = ''
      }
      if(!next && this.draftOrders.page === 0 && !refresh && !filterChange){
        return
      }
      if(next && !this.draftOrders.hasMore && !refresh && !filterChange){
        return
      }
      if(this.draftOrders.loading){
        return
      }
      this.draftOrders.loading = true
      if(!next && this.draftOrders.page > 0 && !filterChange){
        this.draftOrders.page -= 1
      }
      if(!init && !refresh && next && this.draftOrders.hasMore && !filterChange){
        this.draftOrders.page += 1
      }
      const filterArray = []
      const keys = ['filterQuery', 'dateStart', 'dateEnd']
      keys.forEach(key => {
        if(this.draftOrders[key]){
          filterArray.push({ key, value: this.draftOrders[key] })
        }
      })
      const { orders, hasMore } = await fetchDraftOrders(this.draftOrders.page, this.auth.company._id, filterArray)
      this.draftOrders.hasMore = hasMore
      this.draftOrders.content = orders
      this.draftOrders.loaded = true
    }catch(err){
      console.log(err)
    }finally{
      this.draftOrders.loading = false
    }
  }

  async saveDraftOrder(products, name, shippingAddress, shippingMethod, selfPickup, existingID = null){
    try{
      this.draftSaveLoading = true
      const body = {
        products,
        name,
        shippingMethod,
        company: this.auth.company._id,
        shippingAddress,
        selfPickup,
        existingID
      }
      const { order } = await postDraftOrder(body)
      if(order){
        return true
      }
    }catch(err){
      console.log(err)
    }finally{
      this.draftSaveLoading = false
    }
  }

  async deleteDraftOrder(orderID){
    try{
      await deleteDraft(orderID)
      this.getDraftOrders(false, true)
    }catch(err){
      console.log(err)
    }
  }

}

export default OrderStore