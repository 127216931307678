import { MobXProviderContext, observer } from "mobx-react";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Toast from "./Partials/Toast";

const PublicLayout = observer(({ children, title }) => {

  const { 
    auth: { handleUserLangSelect }
  } = useContext(MobXProviderContext)

  const { i18n, t } = useTranslation()
  const { pathname } = useLocation()

  useEffect(() => {
    document.title = `${t(title)} | ISB Tellimiskeskus`
  }, [])

  useEffect(() => {
    document.title = `${t(title)} | ISB Tellimiskeskus`
  }, [pathname])

  useEffect(() => {
    const supported = ['lv', 'lv-LV', 'fi', 'fi-FI', 'et-EE', 'et']
    if(!supported.includes(i18n.language)){
      i18n.changeLanguage('et')
      handleUserLangSelect('et')
    }else{
      handleUserLangSelect(i18n.language)
    }
  }, [i18n.language])

  return (
    <div className="public-layout">
      <Toast />
      {children}
    </div>
  )

})

export default PublicLayout