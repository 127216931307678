import { MobXProviderContext, observer } from "mobx-react";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { ReactComponent as UserIcon } from '../../svg/user-main.svg';
import { ReactComponent as Plus } from '../../svg/plus-circle.svg';
import { ReactComponent as Close } from '../../svg/x-close.svg';
import SectionTitle from "../Partials/SectionTitle";
import { t } from "i18next";
import Checkbox from "../Partials/Checkbox";
import { deleteUserRequest, postUserAddRequest, putUserUpdateRequest } from "../../Store/api";
import LoadingScreen from "../Partials/LoadingScreen";

const Settings = observer(() => {

  const { t, i18n } = useTranslation()
  const [modal, setModal] = useState(false)
  const [deleteTarget, setDeleteTarget] = useState(null)
  const [newUser, setNewUser] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const modalRef = useRef()

  const { auth: { getCompanyDetails, company, companyUsers, user, companyLoaded, detailsLoading }, toast: { setToast, clearToast } } = useContext(MobXProviderContext)

  useEffect(() => {
    getCompanyDetails()
  }, [])

  const { address, registryCode, vatNumber, name, email, contactNumber } = company

  const isAdmin = () => String(user._id) === String(company.admin)

  const showDeleteConfirm = (user) => {
    if(isAdmin()){
      setDeleteTarget(user) 
    }else{
      setToast(t("notCompanyManager", true, 5000))
    }
  }

  const confirmDelete = async () => {
    try{
      const { deleted } = await deleteUserRequest(deleteTarget._id, company._id, i18n.language)
      if(deleted){
        setDeleteTarget(null)
        getCompanyDetails(true)
        setToast(t("userDeleted"), false, 5000)
      }
    }catch(err){
      console.log(err)
    }
  }

  const selectUser = user => {
    if(isAdmin()){
      setNewUser(false)
      setSelectedUser(user)
      setModal(true)
    }else{
      setToast(t("notCompanyManager", true, 5000))
    }
  }

  const getCompanyUsers = () => {
    const firstUser = companyUsers.find(({ _id: userID }) => userID === company.admin)
    const userList = companyUsers.filter(({ _id: userID }) => userID !== firstUser._id)
    return (
      <>
        <div className="settings_body-table-content-item">
            <div key={firstUser._id} className="row g-0">
              <div className="col-5">
                <span className="md-text">{firstUser ? firstUser.firstName : ''} {firstUser ? firstUser.lastName : ''}</span>
              </div>
              <div className="col-5">
                <span className="md-text">{t('manager')}</span>
              </div>
              <div className="col-2">
                <div className="settings_body-table-content-item-actions">
                  <button onClick={() => selectUser(user)} className="btn-none btn-0 p-0 underline md-text text-100">
                    {t("change")}
                  </button>
                </div>
              </div>
            </div>
        </div>
        {
          userList.map(user => {
            return (
            <div key={user._id} className="settings_body-table-content-item">
              <div className="row g-0">
                <div className="col-5">
                  <span className="md-text">{user.firstName} {user.lastName}</span>
                </div>
                <div className="col-5">
                  <span className="md-text">{t('user')}</span>
                </div>
                <div className="col-2">
                  <div className="settings_body-table-content-item-actions">
                    {
                      deleteTarget && user._id === deleteTarget._id ? (
                        <>
                          <button onClick={() => setDeleteTarget(null)} className="btn-none btn-0 p-0 underline md-text text-100">
                            {t("dismiss")}
                          </button>
                          <button onClick={() => confirmDelete()} className="btn-none btn-0 p-0 underline md-text text-100">
                            {t("confirm")}
                          </button>
                        </>
                      )
                      :
                      (
                        <>
                          <button onClick={() => selectUser(user)} className="btn-none btn-0 p-0 underline md-text text-100">
                            {t("change")}
                          </button>
                          <button onClick={() => showDeleteConfirm(user)} className="btn-none btn-0 p-0 underline md-text text-100">
                            {t("delete")}
                          </button>
                        </>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
            )
          })
        }
      </>
    )
  }

  const saveUser = async (body) => {
    try{
      if(newUser){
        const { user } = await postUserAddRequest(body, company._id, i18n.language)
        if(user){
          closeModal()
          setNewUser(false)
          getCompanyDetails(true)
        }
      }else{
        const { user } = await putUserUpdateRequest({ ...body, userID: selectedUser._id }, company._id, i18n.language)
        if(user){
          closeModal()
          setSelectedUser(null)
          getCompanyDetails(true)
        }
      }
    }catch(err){
      console.log(err)
      if(err.response.data.message){
        setToast(err.response.data.message, true, 5000)
        //show error
      }
    }
  }

  const handleAddUser = () => {
    if(isAdmin()){
      setSelectedUser(null)
      setNewUser(true)
      setModal(true)
    }else{
      setToast(t("notCompanyManager", true, 5000))
    }
  }

  const handleModalClick = e => {
    if(e.target === modalRef.current){
      closeModal()
    }
  }

  const closeModal = () => {
    setModal(false)
    setNewUser(false)
    setSelectedUser(null)
  }

  return (
    <div className="settings">
      <div className="settings_details">
        <DetailRow title={t("companyName")} value={name} />
        <DetailRow title={t("regCode")} value={registryCode} />
        <DetailRow title={t("vatID")} value={vatNumber} />
        <DetailRow title={t("address")} value={address} />
        <DetailRow title={t("email")} value={email} />
        <DetailRow title={t("phone")} value={contactNumber} />
        <div className="settings_details-notice">
          <span className="contrast-md-text text-67">{t("updateDetailsRequest")}</span>
        </div>
      </div>
      <div className="settings_body">
        <div className="settings_body-table">
          <SectionTitle titleKey={t('users')} icon={<UserIcon />} className="light border-bottom" />
          <div className="settings_body-table-header">
            <div className="row g-0">
              <div className="col-5">
                <label className="contrast-md-text text-color-100">{t('user')}</label>
              </div>
              <div className="col-5">
                <label className="contrast-md-text text-color-100">{t('role')}</label>
              </div>
              <div className="col-2">
                <label className="contrast-md-text text-color-100">{t('actions')}</label>
              </div>
            </div>
          </div>
          <div className="settings_body-table-content">
            {
              detailsLoading || !companyLoaded || !company || !company._id ? (
                <LoadingScreen />
              )
              :
              (
                getCompanyUsers()
              )
            }
          </div>
          <div className="settings_body-table-footer">
            <div className="row g-0">
              <div className="col-12">
                <button onClick={() => handleAddUser()} className="btn-none save-draft btn-0 prim-btn add-user-btn">
                  <Plus />
                  {t('addNewUser')}
                </button>
              </div>
              <div className="col-12">
                <p className="md-text text-87">
                  {t("userNotice1")}
                </p>
                <p className="md-text text-87">
                  {t("userNotice2")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div onClick={e => handleModalClick(e)} ref={modalRef} className={`modal fade ${modal ? 'show' : ''}`}>
        <div className="modal-dialog">
          <div className="modal-wrapper settings-modal">
            <div className="modal-header border-bottom">
              <h1 className="modal-title">
                {
                  modal && newUser && !selectedUser ? t("addUser") : t("updateUser")
                }
              </h1>
              <button onClick={() => closeModal()} className="btn-none btn-0 ms-auto">
                <Close />
              </button>
            </div>
            <UserModalContent 
              newUser={newUser}
              selectedUser={selectedUser}
              saveUser={(details) => saveUser(details)}
              company={company} 
            />
          </div>
        </div>
      </div>
    </div>
  )

})

const DetailRow = ({ title, value }) => {
  return (
    <div className="row g-0">
      <div className="col-lg-3 col-md-4 col-4">
        <p className="contrast-md-text">{title}</p>
      </div>
      <div className="col-lg-8 col-md-8 col-8">
        <span className="md-text">{value}</span>
      </div>
    </div>
  )
}

const UserModalContent = ({ newUser, selectedUser, saveUser, close }) => {

  const [requiredError, setRequiredError] = useState(null)
  const [agreedTerms, setAgreedTerms] = useState(false)
  const [phoneExt, setPhoneExt] = useState([
    {
      value: '+372',
      selected: true
    },
    {
      value: '+371',
      selected: false,
    },
    {
      value: '+358',
      selected: false
    }
  ])
  const [fields, setFields] = useState({
    firstName: {
      name: "firstName",
      label: t("firstname"),
      value: ''
    },
    lastName: {
      name: "lastName",
      label: t("lastname"),
      value: ''
    },
    email: {
      name: "email",
      label: t("email"),
      value: ''
    },
    number: {
      name: 'number',
      label: t('contactNumber'),
      value: ''
    }
  })

  const syncFields = () => {
    const copy = {...fields}
    Object.keys(selectedUser).forEach(key => {
      if(key === 'number'){
        const extCopy = [...phoneExt]
        extCopy.forEach(ext => {
          if(selectedUser[key].includes(ext.value)){
            const phoneValue = selectedUser[key].replace(ext.value, '')
            copy[key].value = phoneValue
            ext.selected = true
          }else{
            ext.selected = false
          }
        })
        if(selectedUser[key].length === 0){
          const item = extCopy.find(({ value }) => value === '+372')
          item.selected = true
        }
        setPhoneExt(extCopy)
      }else{
        if(copy[key]){
          copy[key].value = selectedUser[key]
        }
      }
    })
    setFields(copy)
  }

  const initFields = () => {
    setAgreedTerms(false)
    setRequiredError(null)
    setPhoneExt([
      {
        value: '+372',
        selected: true
      },
      {
        value: '+371',
        selected: false,
      },
      {
        value: '+358',
        selected: false
      }
    ])
    const copy = {...fields}
    Object.keys(copy).forEach(key => {
      copy[key].value = ''
    })
    setFields(copy)
  }

  useEffect(() => {
    if(selectedUser){
      syncFields()
    }else{
      initFields()
    }
  }, [newUser, selectedUser])

  const handleFieldChange = (e) => {
    const { name, value } = e.target
    const copy = {...fields}
    copy[name].value = value
    setFields(copy)
  }

  const handlePhoneChange = (e, extension) => {
    const { value } = e.target
    if(extension){
      const copy = [...phoneExt]
      copy.forEach(item => {
        if(item.value !== value){
          item.selected = false
        }else{
          item.selected = true
        }
      })
      setPhoneExt(copy)
    }else{
      setRequiredError(null)
      const { value } = e.target
      const copy = { ...fields }
      copy.number.value = value
      setFields(copy)
    }
  }

  const getPhoneFields = () => {
    const baseObject = phoneExt
    const targetObject = fields.number
    let activeOption = baseObject.find(({ selected }) => selected)
    return (
      <div className="form-group me-0">
        <label>{targetObject.label}*</label>
          <div className="phone-input">
            <select className="phone-input_select" value={activeOption.value} onChange={e => handlePhoneChange(e, true)}>
              {
                baseObject.map(ext => (
                  <option value={ext.value} key={ext.value}>{ext.value}</option>
                ))
              }
            </select>
            <input className="form-control phone-input_number" type="text" value={targetObject.value} onChange={e => handlePhoneChange(e, false)} />
          </div>
      </div>
    )
  }

  const getUserFields = () => {
    return (
      <div className="settings-modal_fields">
        <div className="row g-0">
          {
            Object.keys(fields).map(key => {
              const field = fields[key]
              if(field.name === 'number'){
                return (
                  <div key={key} className="col-lg-3">
                    {getPhoneFields()}
                  </div>
                )
              }else{
                return (
                  <div key={key} className="col-lg-3">
                    <div className="form-group">
                      <label>{field.label}*</label>
                      <input type="text" name={field.name} value={field.value} className="form-control" onChange={e => handleFieldChange(e)} />
                    </div>
                  </div>
                )
              }
            })
          }
        </div>
      </div>
    )
  }

  const save = () => {
    const body = {}
    if(!agreedTerms){
      setRequiredError(t("termsNotAccepted"))
      return
    }
    let missing = false
    Object.keys(fields).forEach(key => {
      const field = fields[key]
      if(field.value === ''){
        setRequiredError(`${t("mandatoryField")} ${field.label} ${t("incomplete")}`)
        missing = true
      }
      if(key === 'number'){
        const { value } = phoneExt.find(({ selected }) => selected)
        body.number = `${value}${field.value}`
      }else{
        body[key] = field.value
      }
    })
    if(missing){
      return
    }else{
      saveUser(body)
    }
  }

  const handleTerms = (val) => {
    setAgreedTerms(val)
  }

  return (
    <div className="modal-body">
      {
        getUserFields()
      }
      <div className="modal-body_footer">
        {
          requiredError ? (<div className="input-error"><span>{requiredError}</span></div>) : undefined
        }
        <Checkbox checked={agreedTerms} onChange={val => handleTerms(val)} label={<span>{t("addUserTerms")} <a href="/privaatsus-ja-kasutustingimused" target="_blank" className="link">{t("termsLinkText2")}</a></span>} />
        <button onClick={() => save()} className="btn-dark">
          {t("saveUser")}
        </button>
      </div>
    </div>
  )

}

export default Settings