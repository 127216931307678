import { observer } from "mobx-react";
import { ReactComponent as Logo } from '../../svg/solider-logo.svg'
import LanguageSelect from "../Partials/LanguageSelect";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

const LimitedCompany = observer(() => {
  
  const { t } = useTranslation()

  return (
    <div className="limited">
      <div className="limited_header">
        <div className="limited_header-content">
          <Logo />
          <LanguageSelect />
        </div>
      </div>
      <div className="limited_body">
        <div className="limited_body-content">
          <h1 className="lg-title">{t("limitedTitle")}</h1>
          <p className="lg-text text-87">{t("limitedBody1")}</p>
          <p className="lg-text text-87">{t("limitedBody2")}</p>
        </div>
        <div className="limited_body-links">
          <Link className="link underline" to={"/privaatsus-ja-kasutustingimused"}>
            {t("termsLinkText")}
          </Link>
        </div>
      </div>
    </div>
  )

})

export default LimitedCompany