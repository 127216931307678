import { useTranslation } from 'react-i18next';

const SectionTitle = ({ icon, titleKey, extraText = '', className = '' }) => {

  const { t } = useTranslation()

  return (
    <div className={`section-title ${className ? className : 'default'}`}>
      <div className="section-title_icon">
        {icon}
      </div>
      <p className="section-title_label">{t(titleKey)}{extraText}</p>
    </div>
  )
}

export default SectionTitle