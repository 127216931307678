import { makeAutoObservable, configure } from "mobx";
import { fetchCompanyDetails, fetchNotification, fetchUser, postLoginCode, postLoginRequest } from "./api";

configure({
  enforceActions: "never",
})

class AuthStore {
  constructor(toaster){
    makeAutoObservable(this, {}, { autoBind: true })
    this.toaster = toaster
  }

  lang = 'et'
  langOptions = [
    {
      code: 'et',
      name: 'Estonian'
    },
    {
      code: 'lv',
      name: 'Latvia'
    },
    {
      code: 'fi',
      name: 'Finnish'
    }
  ]

  detailsLoading = false
  companyLoaded = false
  showTaxRow = true
  userLoaded = false
  codeLoading = false

  user = {}
  authToken = null

  company = {

  }

  companyUsers = []

  notification = {
    details: null,
    dismissed: false,
    loaded: false
  }
  
  companyList = []
  authenticated = false

  async changeCompany(id){
    try{
      const target = this.companyList.find(({ _id }) => _id === id)
      if(target){
        this.company = target
        this.getCompanyDetails(true)
      }
    }catch(err){
      console.log(err)
    }
  }

  async getLoginCode(email){
    try{
      if(this.codeLoading){
        return
      }
      this.codeLoading = true
      const res = await postLoginRequest({ email }, this.lang)
      return res
    }catch(err){
      console.log(err)
      return {
        error: true,
        message: err.response.data.message
      }
    }finally{
      this.codeLoading = false
    }
  }

  logout(){
    this.user = {}
    this.company = {}
    this.companyList = []
  }

  async confirmLogin(code){
    try{
      this.codeLoading = true
      const { token, user, companies } = await postLoginCode({code}, this.lang)
      localStorage.setItem('solider-client-side-auth-token', token)
      this.user = user
      this.token = token
      if(user.level !== 'admin'){
        this.companyList = companies
        this.company = companies[0]
        const lower = companies[0].addressLines.country.toLowerCase()
        this.showTaxRow = lower === 'estonia' || lower === 'eesti'
        await this.getCompanyDetails()
      }
      return { status: true, user }
    }catch(err){
      console.log(err)
      return {
        error: true,
        message: err.response.data.message
      }
      this.toaster.setToast(err.response.data.message, true, 5000)
    }finally{
      this.codeLoading = false
    }
  }

  async getCompanyDetails(force = false){
    try{
      if(this.detailsLoading){
        return
      }
      if(this.companyLoaded && !force){
        return
      }
      const { user } = await fetchUser(this.lang)
      this.user = user
      this.detailsLoading = true
      let id = this.company._id
      if(!this.company._id){
        id = user.companies[0]
      }
      if(user.level !== 'admin'){
        const { company, users, companyList } = await fetchCompanyDetails(id)
        const { notification: activeNotification } = await fetchNotification(this.lang)
        if(activeNotification && activeNotification._id){
          this.notification.details = activeNotification
        }
        this.companyList = companyList
        const lower = company.addressLines.country.toLowerCase()
        this.showTaxRow = lower === 'estonia' || lower === 'eesti'
        this.notification.loaded = true
        this.company = company
        this.companyUsers = users
        this.detailsLoading = true
      }
    }catch(err){
      console.log(err)
      this.detailsLoading = false
      this.companyLoaded = true
    }finally{
      this.detailsLoading = false
      this.companyLoaded = true
    }
  }

  autoSetUserLanguage(){
    const langCode = localStorage.getItem('user-selected-language')
    if(!langCode){
      localStorage.setItem('user-selected-language', this.lang)
    }else{
      this.lang = langCode
    }
  }

  handleUserLangSelect(code){
    this.lang = code
  }

}

export default AuthStore