import { MobXProviderContext, observer } from "mobx-react";
import { useContext, useEffect, useRef, useState } from "react";
import LoadingScreen from '../Partials/LoadingScreen';
import Pagination from '../Partials/Pagination';
import { ReactComponent as Close } from '../../svg/x-close.svg';
import { ReactComponent as PlusIcon } from '../../svg/plus-circle.svg';
import { ReactComponent as SearchIcon } from '../../svg/search.svg';
import { ReactComponent as Chevron } from '../../svg/chevron-down.svg';
import { format } from 'date-fns';
import Spinner from "../Partials/Spinner";
import { adminDeleteCompany, adminDeleteUser, adminUpdateCompany, fetchAdminCompanyDetails, postAdminUser, putAdminEditUser } from "../../Store/api";
import { useTranslation } from 'react-i18next';
let _debounce = null

const Admin = observer(() => {

  const { admin: { companies, getCompanies, setSearchTerm }, auth, toast: { setToast } } = useContext(MobXProviderContext)

  const [modal, setModal] = useState(false)
  const [company, setCompany] = useState(null)
  const [deleteTarget, setDeleteTarget] = useState(null)
  const [companyUsers, setCompanyUsers] = useState(null)
  const [detailsLoading, setDetailsLoading] = useState(false)
  const modalRef = useRef()

  const showDeleteConfirm = (company) => {
    setDeleteTarget(company)
  }


  const confirmDelete = async () => {
    try{
      const { deleted, message } = await adminDeleteCompany(deleteTarget.registryCode)
      if(deleted){
        setDeleteTarget(null)
        getCompanies(true)
        setToast(message, false, 5000)
      }
    }catch(err){
      setToast(err.response.data.message, false, 5000)
      console.log(err)
    }
  }

  useEffect(() => {
    if(!companies.loaded && !companies.loading){
      getCompanies()
    }
  }, [])

  const getStatus = ({ approved, canOrder }) => {
    if(!approved){
      return 'Kinnitamata'
    }
    if(approved && !canOrder){
      return 'Limiteeritud'
    }
    if(approved && canOrder){
      return 'Aktiivne'
    }
  }

  const saveCompanyDetails = async (notes, statusText) => {
    const status = statusText.toLowerCase()
    const body = {
      approved: status === 'aktiveeritud' || status === 'limiteeritud',
      canOrder: status !== 'limiteeritud',
      notes,
      _id: company._id
    }
    try{
      const { company: details } = await adminUpdateCompany(body)
      closeModal()
      getCompanies(true)
    }catch(err){
      console.log(err)
    }
  }

  const handleUserDelete = async (companyID, userID) => {
    try{
      setDetailsLoading(true)
      const { users } = await adminDeleteUser(companyID, userID)
      setCompanyUsers(users)
    }catch(err){
      console.log(err)
    }finally{
      setDetailsLoading(false)
    }
  }

  const handleUserSave = async body => {
    try{
      setDetailsLoading(true)
      const { company, users } = await putAdminEditUser(body)
      setCompany(company)
      setCompanyUsers(users)
    }catch(err){
      console.log(err)
    }finally{
      setDetailsLoading(false)
    }
  }

  const handleChange = e => {
    const { value } = e.target
    debounceSearch(value)
  }

  const debounceSearch = value => {
    clearTimeout(_debounce)
    _debounce = setTimeout(() => {
      setSearchTerm(value)
    }, 750)
  }

  const handleCompanyClick = async (companyDetails) => {
    try{
      setCompany(companyDetails)
      const { registryCode } = companyDetails
      setDetailsLoading(true)
      setModal(true)
      const { company, users } = await fetchAdminCompanyDetails(registryCode)
      setCompany(company)
      setCompanyUsers(users)
      getCompanies(true)
    }catch(err){
      console.log(err)
    }finally{
      setDetailsLoading(false)
    }
  }

  const handleModalClick = e => {
    if(e.target === modalRef.current){
      closeModal()
    }
  }

  const handleNewUserSave = async body => {
    console.log(body)
    try{
      setDetailsLoading(true)
      const { company, users } = await postAdminUser(body)
      setCompany(company)
      setCompanyUsers(users)
    }catch(err){
      console.log(err)
    }finally{
      setDetailsLoading(false)
    }
  }

  const deleteCompany = id => {

  }

  const closeModal = () => {
    setModal(false)
    setCompany(null)
  }

  const handlePagination = (next) => {
    //if(companies.hasMore){
      getCompanies(false, next, !next)
   //}
  }


  return (
    <div className="admin">
      <div className="admin_header">
        <div className="row g-0">
          <div className="col-lg-4">
            <div className="admin_header-content search-form">
              <div className="form-group">
                <SearchIcon />
                <input onKeyUp={e => handleChange(e)} className="form-control" type="text" name="searchQuery" placeholder={"Otsi nime või reg koodi järgi"} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="admin_body">
        {
          !companies.loaded ? (
            <LoadingScreen admin={true} />
          )
          :
          (
            <div className="order-table">
              <div className="order-table_header">
                <div className="row g-0">
                  <div className="col-lg-4 order-table_header-left">
                    <label>Ettevõtte nimi</label>
                  </div>
                  <div className="col-lg-2">
                    <label>Reg nr</label>
                  </div>
                  <div className="col-lg-2">
                    <label>Staatus</label>
                  </div>
                  <div className="col-lg-2">
                    <label>Viimane tellimus</label>
                  </div>
                  <div className="col-lg-2">
                    <label>Tegevused</label>
                  </div>
                </div>
              </div>
              <div className="order-table_body">
                {
                    companies.content.map((company) => {
                      const { _id, lastOrderDate, approved, canOrder, registryCode, name } = company
                      return (
                        <div key={_id} className="row g-0 order-table_body-row">
                          <div className="col-lg-4 order-table_body-left">
                            <label className="text-100 md-text">{name}</label>
                          </div>
                          <div className="col-lg-2">
                            <label className="text-100 md-text">{registryCode}</label>
                          </div>
                          <div className="col-lg-2">
                            <label className="text-100 md-text">{getStatus(company)}</label>
                          </div>
                          <div className="col-lg-2">
                            <label className="text-100 md-text">{format(new Date(lastOrderDate), 'dd.MM.yyyy')}</label>
                          </div>
                          <div className="col-lg-2">
                            <div className="order-table_body-actions">
                              {
                                deleteTarget && deleteTarget._id === company._id ? (
                                  <>
                                    <button onClick={() => setDeleteTarget(null)} className="btn-none btn-0 p-0 underline md-text text-100">
                                      Tühista
                                    </button>
                                    <button onClick={() => confirmDelete()} className="btn-none btn-0 p-0 ms-3 underline md-text text-100">
                                      Kinnita
                                    </button>
                                  </>
                                )
                                :
                                (
                                  <>
                                    <button onClick={() => handleCompanyClick(company)} className="btn-none btn-0 p-0 underline md-text text-100">
                                      {approved ? "Info" : "Muuda"}
                                    </button>
                                    <button onClick={() => showDeleteConfirm(company)} className="btn-none btn-0 p-0 ms-3 underline md-text text-100">
                                      Kustuta
                                    </button>
                                  </>
                                )
                              }
                            </div>
                          </div>
                        </div>
                      )
                    })
                  //)
                }
              </div>
            </div>
          )
        }
      <Pagination disableNext={!companies.hasMore} disablePrevious={companies.page <= 1} handleClick={next => handlePagination(next)} className={'admin_body-pagination'} />
      </div>
      <div onClick={e => handleModalClick(e)} ref={modalRef} className={`modal fade ${modal ? 'show' : ''}`}>
        <div className="modal-dialog company-mdl-dialog">
          <div className="modal-wrapper company-modal">
            <div className="modal-header border-bottom">
              <h1 className="modal-title">
                {
                  modal && company && company._id ? (
                    company.approved ? "Muuda ettevõtet" : "Kinnitamata ettevõte" 
                  )
                  :
                  undefined
                }
              </h1>
              <button onClick={() => closeModal()} className="btn-none btn-0 ms-auto">
                <Close />
              </button>
            </div>
            <CompanyModalContent 
              deleteCompany={id => deleteCompany(id)}
              open={modal}
              saveCompany={(notes, status) => saveCompanyDetails(notes, status)}
              companyUsers={companyUsers}
              detailsLoading={detailsLoading}
              company={company} 
              saveUser={body => handleUserSave(body)}
              handleUserDelete={(companyID, userID) => handleUserDelete(companyID, userID)}
              saveNewUser={body => handleNewUserSave(body)}
            />
          </div>
        </div>
      </div>
    </div>
  )

})

export default Admin

const CompanyModalContent = ({ company, detailsLoading, companyUsers, saveCompany, saveUser, saveNewUser, open, handleUserDelete }) => {

  const [companyStatus, setCompanyStatus] = useState(null)
  const [selectedUser, setSelectedUser] = useState(null)
  const [deleteTarget, setDeleteTarget] = useState(null)
  const [newUser, setNewUser] = useState(null)
  const [notes, setNotes] = useState('')

  const handleNotesChange = e => {
    const { value } = e.target
    setNotes(value)
  }

  useEffect(() => {
    if(!open){
      setSelectedUser(null)
      setNewUser(null)
    }
  }, [open])

  const handleStatusChange = e => {
    const { value } = e.target
    setCompanyStatus(value)
  }

  const handleSave = () => {
    saveCompany(notes, companyStatus)
  }

  const confirmUserDelete = () => {
    handleUserDelete(company._id, deleteTarget._id)
    setDeleteTarget(null)
  }

  useEffect(() => {
    if(!company){
      return
    }
    if(company.approved && company.canOrder){
      setCompanyStatus('aktiveeritud')
    }
    if(company.approved && !company.canOrder){
      setCompanyStatus('limiteeritud')
    }
    if(!company.approved){
      setCompanyStatus('kinnitamata')
    }
    setNotes(company.notes ? company.notes : '')
  }, [company])

  if(!company || detailsLoading){
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '400px' }}><Spinner /></div>
  }

  const { name, registryCode, vatNumber, addressLines: { country, state, city, street, street2, postalCode }, admin, email, contactNumber } = company

  const getDetails = () => {
    return [{
        label: 'Ettevõtte nimi',
        value: name
      },
      {
        label: 'Registrikood',
        value: registryCode
      },
      {
        label: 'KMKR',
        value: vatNumber
      },
      {
        label: 'Aadress',
        value: `${street}${street2 ? `, ${street2}` : ''}, ${postalCode}, ${city}, ${state}`
      },
      {
        label: 'Telefon',
        value: contactNumber
      },
      {
        label: 'Riik',
        value: country
      }].map(({ label, value }) => (
      <div key={label} className="row g-0">
        <div className="col-lg-2 col-md-2 col-6">
          <label className="text-87 contrast-lg-text">{label}</label>
        </div>
        <div className="col-10">
          <label className="text-100 lg-text">{value}</label>
        </div>
      </div>
    ))
  }

  const saveNewUserDetails = fields => {
    const body = {
      companyID: company._id
    }
    Object.keys(fields).forEach(key => {
      const { name, value } = fields[key]
      body[name] = value
    })
    saveNewUser(body)
    setNewUser(null)
  }

  const saveUserDetails = (fields, id) => {
    const body = {
      userID: id,
      companyID: company._id
    }
    Object.keys(fields).forEach(key => {
      const { name, value } = fields[key]
      body[name] = value
    })
    saveUser(body)
    setSelectedUser(null)
  }

  const getCompanyUsers = () => {
    return (
      <div className="company-modal_users">
        <span className="contrast-md-text">Kasutajad</span>
        {
          companyUsers.map((user) => {
            const { _id, firstName, lastName, email } = user
            return (
              selectedUser && selectedUser._id === _id ?  (
                <UserInputs isManager={_id === admin} cancel={() => setSelectedUser(null)} user={selectedUser} save={(fields) => saveUserDetails(fields, _id)} />
              )
              :
              (
                <div key={_id} className="row g-0">
                  <div className="col-3">
                    <span className="text-md">{firstName} {lastName}</span>
                  </div>
                  <div className="col-3">
                    <span className="text-md">{email}</span>
                  </div>
                  <div className="col-3">
                    <span className="text-md">{admin === _id ? 'Peakasutaja' : 'Kasutaja'}</span>
                  </div>
                  <div className="col-3">
                    {
                      deleteTarget && deleteTarget._id === _id ? (
                        <>
                          <button onClick={() => setDeleteTarget(null)} className="btn-none btn-0 p-0 underline md-text text-100">
                            Tühista
                          </button>
                          <button onClick={() => confirmUserDelete()} className="btn-none btn-0 p-0 ms-3 underline md-text text-100">
                            Kinnita
                          </button>
                        </>
                      )
                      :
                      (
                        <>    
                          <button onClick={() => setSelectedUser(user)} className="btn-none btn-0 underline p-0">
                            Muuda
                          </button>
                          <button onClick={() => setDeleteTarget(user)} className="btn-none btn-0 underline p-0 ms-2">
                            Kustuta
                          </button>
                        </>
                      )
                    }
                  </div>
                </div>
              )
            )
          })
        }
      </div>
    )
  }

  const getAddUser = () => {
    return (
      <div className="company-modal_new-user">
        <span className="contrast-md-text">Lisa uus kasutaja</span>
        <UserInputs isManager={false} cancel={() => setSelectedUser(null)} newUser={true} user={null} save={(fields) => saveNewUserDetails(fields)} />
      </div>
    )
  }

  return (
    <div className="modal-body">
      <div className="company-modal_details">
        {getDetails()}
      </div>
      <div className="company-modal_status">
        <div className="row g-0">
          <div className="col-6">
            <div className="form-group">
              <label className="text-md">Staatus</label>
              <div className="select">
                <select onChange={e => handleStatusChange(e)} value={companyStatus} className="select_input">
                  <option value="aktiveeritud">Aktiveeritud</option>
                  <option value="limiteeritud">Limiteeritud</option>
                  <option value="kinnitamata">Kinnitamata</option>
                </select>
                <Chevron className="select_chevron" />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="text-md">Märkmed</label>
              <input className="form-control" onChange={e => handleNotesChange(e)} value={notes} />
            </div>
          </div>
        </div>
        {getCompanyUsers()}
        {newUser ? getAddUser() : (<button onClick={() => setNewUser(true)} className="add-user-btn btn-none btn-0 prim-btn">Lisa kasutaja <PlusIcon /></button>)}
        <div className="company-modal_footer">
          <div className="row g-0">
            <div className="col-6">
              <button onClick={() => handleSave()} className="btn-dark">
                Salvesta muudatused
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}

const UserInputs = ({user, save, cancel, newUser, isManager}) => {
  const [fields, setFields] = useState({
    firstName: {
      name: 'firstName',
      value: '',
      label: 'Eesnimi'
    },
    lastName: {
      name: 'lastName',
      value: '',
      label: 'Perekonnanimi'
    },
    email: {
      name: 'email',
      value: '',
      label: 'E-posti aadress'
    },
    level: {
      select: true,
      name: 'level',
      value: '',
      label: 'Roll',
      options: [
        {
          value: 'manager',
          label: 'Peakasutaja'
        },
        {
          value: 'customer',
          label: 'Kasutaja'
        }
      ]
    },
  })
  const [reqErr, setReqErr] = useState(null)

  const handleSave = () => {
    let err = false
    Object.keys(fields).forEach(key => {
      const { value, label } = fields[key]
      if(value === ''){
        err = true
        setReqErr(`Kohustuslik väli ${label} täitmata`)
      }
    })
    if(err){
      return
    }
    save(fields)
  }

  const syncFields = () => {
    const copy = {...fields}
    Object.keys(user).forEach(key => {
      if(copy[key]){
        copy[key].value = user[key]
      }
    })
    copy.level.value = isManager ? 'manager' : 'customer'
    setFields(copy)
  }

  useEffect(() => {
    setReqErr(null)
    if(user && user._id){
      syncFields()
    }else{
      const copy = {...fields}
      copy.level.value = 'customer'
      setFields(copy)
    }
  }, [user])

  const handleChange = e => {
    setReqErr(null)
    const { name, value } = e.target
    const copy = {...fields}
    copy[name].value = value
    setFields(copy)
  }

  return (
    <div className="admin_edit-user">
      <div className="row g-0">
        {Object.keys(fields).map(key => {
          const { name, select, value, label, options } = fields[key]
          if(isManager && name === 'level'){
            return <div key={key} />
          }
          return (
            <div key={key} className={"col-lg-3"}>
              <div className="form-group">
                <label>{label}*</label>
                {
                  select ? (
                    <select className="form-control" name={'level'} value={value} onChange={e => handleChange(e)}>
                      {
                        options.map(({ value, label }) => (
                          <option value={value} key={label}>{label}</option>
                        ))
                      }
                    </select>
                  )
                  :
                  (
                    <input className="form-control" type="text" value={value} onChange={e => handleChange(e)} name={name} />
                  )
                }
              </div>
            </div>
          )
        })}
      </div>
      <button onClick={() => cancel()} className="btn-none btn-0 underline p-0 mb-2">
        Tühista
      </button>
      <button onClick={() => handleSave(fields)} className="btn-none btn-0 underline p-0 ms-2 mb-2">
        Salvesta
      </button>
      {reqErr ? (<div className="err"><span>{reqErr}</span></div>) : undefined}
    </div>
  )
}