import { MobXProviderContext, observer } from "mobx-react";
import { ReactComponent as Logo } from '../../svg/solider-logo-light.svg'
import LanguageSelect from "../Partials/LanguageSelect";
import { ReactComponent as BriefcaseIcon } from '../../svg/briefcase.svg';
import { ReactComponent as Chevron } from '../../svg/chevron-down.svg';
import { ReactComponent as UserIcon } from '../../svg/user-white.svg';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import SectionTitle from "../Partials/SectionTitle";
import { t } from "i18next";
import { postRegisterRequest } from "../../Store/api";
import Checkbox from "../Partials/Checkbox";

const Register = observer(() => {

  const { auth: { handleUserLangSelect } } = useContext(MobXProviderContext)
  const [success, setSuccess] = useState(false)

  const { i18n } = useTranslation()

  useEffect(() => {
    handleUserLangSelect(i18n.language)
  }, [i18n.language])

  useEffect(() => {
    if(success){

    }
  }, success)

  return (
    <div className="register">
      <div className="register_header">
        <div className="register_header-content">
          <Link to={'/'}><Logo /></Link>
          <LanguageSelect className={"light"} />
        </div>
      </div>
      <div className="register_top">
        <div className="register_top-content">
          <h1 className="title-lg text-white">{success ? t("regSuccess") : t("registerTitle")}</h1>
          {success ? undefined : <p className="contrast-lg-text text-white">{t("registerSubtitle")}</p>}
        </div>
      </div>
      <div className="register_body">
        {
          success ? (
            <div className="register_body-success">
              <div className="register_body-success-content">
                <p>{t("regSuccess1")}</p>
                <p>{t("regSuccess2")}</p>
                <p>{t("regSuccess3")}</p>
                <p>{t("regSuccess4")}</p>
                <p>{t("regSuccess5")}</p>
                <p className="mb-0">{t("regSuccess6")}</p>
                <p>{t("regSuccess7")}</p>
              </div>
            </div>
          )
          :
          (
            <div className="register_body-add">
              <RegisterForm setSuccess={status => setSuccess(status)} />
            </div>
          )
        }
      </div>
    </div>
  )

})

const RegisterForm = ({ setSuccess }) => {
  const { i18n } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [requiredError, setRequiredError] = useState(null)
  const [phoneExt, setPhoneExt] = useState([
    {
      value: '+372',
      selected: true
    },
    {
      value: '+371',
      selected: false,
    },
    {
      value: '+358',
      selected: false
    }
  ])
  const [managerPhoneExt, setManagerPhoneExt] = useState([
    {
      value: '+372',
      selected: true
    },
    {
      value: '+371',
      selected: false,
    },
    {
      value: '+358',
      selected: false
    }
  ])
  const [agreedTerms, setAgreedTerms] = useState(false)
  const [companyDetails, setCompanyDetails] = useState({
    fullName: {
      label: t("companyName"),
      value: "",
      required: true
    },
    registryCode: {
      label: t("regCode"),
      value: "",
      required: true
    },
    vatNumber: {
      label: t("vatID"),
      value: ""
    },
    addressLines: {
      street: {
        label: t("line1Label"),
        value: "",
        required: true
      },
      street2: {
        label: t("line2Label"),
        value: ""
      },
      state: {
        label: t("state"),
        value: "",
        required: true
      },
      city: {
        label: t("city"),
        value: "",
        required: true
      },
      postalCode: {
        label: t("zipCode"),
        value: "",
        required: true
      },
      country: {
        label: t("country"),
        value: "Eesti",
        required: true,
        select: true,
        options: ['Eesti', 'Soome', "Läti"]
      }
    },
    contactNumber: {
      label: t("contactNumber"),
      value: "",
      required: true
    },
    email: {
      label: t("email"),
      value: "",
      required: true
    },
    manager: {
      firstName: {
        label: t("managerFirstname"),
        value: "",
        required: true
      },
      lastName: {
        label: t("managerLastname"),
        value: '',
        required: true
      },
      email: {
        label: t("managerEmail"),
        value: '',
        required: true
      },
      number: {
        label: t("managerNumber"),
        value: '',
        required: true
      },
    }
  })

  const handleDetailChange = (e, manager) => {
    setRequiredError(null)
    const { name, value } = e.target
    const copy = {...companyDetails}
    if(manager){
      copy.manager[name].value = value
    }else{
      copy[name].value = value
    }
    setCompanyDetails(copy)
  }

  const handleNestedDetailChange = (e, nestedKey) => {
    setRequiredError(null)
    const { name, value } = e.target
    const copy = {...companyDetails}
    copy[nestedKey][name].value = value
    setCompanyDetails(copy)
  }

  const getFormRow = (fields, className, nestedKey = null, colClass = 'col-lg-4') => {
    const targetObject = nestedKey ? companyDetails[nestedKey] : companyDetails
    return (
      <div className={`row g-0 register_body-add-form-${className}`}>
        {
          fields.map(fieldname => (
            <div key={fieldname} className={colClass}>
              <div className="form-group">
                <label>{targetObject[fieldname].label}{targetObject[fieldname].required ? '*' : ''}</label>
                {
                  targetObject[fieldname].select ? (
                    <>
                      <select className="form-control" value={targetObject[fieldname].value} name={fieldname} onChange={e => nestedKey ? handleNestedDetailChange(e, nestedKey) : handleDetailChange(e)}>
                        {
                          targetObject[fieldname].options.map(option => (
                            <option value={option} key={option}>{option}</option>
                          ))
                        }
                      </select>
                      <Chevron />
                    </>
                  )
                  :
                  (
                    <input type="text" className="form-control" value={targetObject[fieldname].value} name={fieldname} onChange={e => nestedKey ? handleNestedDetailChange(e, nestedKey) : handleDetailChange(e)} />
                  )
                }
              </div>
            </div>
          ))
        }
      </div>
    )
  }

  const handlePhoneChange = (e, extension, manager = false) => {
    const { value } = e.target
    if(extension){
      const copy = manager ? [...managerPhoneExt] : [...phoneExt]
      copy.forEach(item => {
        if(item.value !== value){
          item.selected = false
        }else{
          item.selected = true
        }
      })
      if(manager){
        setManagerPhoneExt(copy)
      }else{
        setPhoneExt(copy) 
      }
    }else{
      setRequiredError(null)
      const { name, value } = e.target
      const copy = {...companyDetails}
      if(manager){
        copy.manager.number.value = value
      }else{
        copy.contactNumber.value = value
      }
      setCompanyDetails(copy)
    }
  }

  const getPhoneSelect = (manager = false) => {
    const baseObject = manager ? managerPhoneExt : phoneExt
    const targetObject = manager ? companyDetails.manager : companyDetails.contactNumber
    let activeOption = baseObject.find(({ selected }) => selected)
    return (
      <div className="form-group">
        <label>{manager ? t("managerNumber") : t("contactNumber")}*</label>
          <div className="phone-input">
            <select className="phone-input_select" value={activeOption.value} onChange={e => handlePhoneChange(e, true, manager)}>
              {
                baseObject.map(ext => (
                  <option value={ext.value} key={ext.value}>{ext.value}</option>
                ))
              }
            </select>
            <input className="form-control phone-input_number" type="text" value={targetObject.value} onChange={e => handlePhoneChange(e, false, manager)} />
          </div>
      </div>
    )
  }

  const saveCompany = async () => {
    //check required fields show error if needed or send to server
    let missingError = null
    const body = {
      company: {
        addressLines: {}
      },
      manager: {}
    }
    if(!agreedTerms){
      setRequiredError(t("termsNotAccepted"))
      return
    }
    Object.keys(companyDetails.addressLines).forEach(key => {
      const item = companyDetails.addressLines[key]
      if(item.required && item.value === ''){
        missingError = `${t("mandatoryField")} "${item.label}" ${t("incomplete")}`
      }
      body.company.addressLines[key] = item.value
    })
    Object.keys(companyDetails).forEach(key => {
      if(key === 'addressLines' || key === 'manager'){
        return
      }
      const item = companyDetails[key]
      if(item.required && item.value === ''){
        missingError = `${t("mandatoryField")} "${item.label}" ${t("incomplete")}`
      }
      body.company[key] = item.value
    })
    Object.keys(companyDetails.manager).forEach(key => {
      const item = companyDetails.manager[key]
      if(item.required && item.value === ''){
        missingError = `${t("mandatoryField")} "${item.label}" ${t("incomplete")}`
      }
      body.manager[key] = item.value
    })
    if(missingError){
      setRequiredError(missingError)
    }else{
      let contactNumber = companyDetails.contactNumber
      let managerNumber = companyDetails.manager.number
      phoneExt.forEach(({ value, selected }) => {
        if(selected){
          contactNumber = `${value}${companyDetails.contactNumber.value}`
        }
      })
      managerPhoneExt.forEach(({ value, selected }) => {
        if(selected){
          managerNumber = `${value}${companyDetails.manager.number.value}`
        }
      })
      body.company.contactNumber = contactNumber
      body.manager.number = managerNumber
      try{
        const { status } = await postRegisterRequest(body, i18n.language)
        if(status){
          setRequiredError(null)
          setSuccess(true)
        }
      }catch(err){
        console.log(err)
        const { data } = err.response
        if(data.error && data.message){
          setRequiredError(data.message)
        }
      }
    }
  }

  const handleTerms = (val) => {
    setAgreedTerms(val)
  }

  const getAddForm = () => {
    return (
      <div className="register_body-add-form">
        <SectionTitle icon={<BriefcaseIcon />} titleKey={"companyDetails"} />
        {getFormRow(['fullName', 'registryCode', 'vatNumber'], 'top')}
        {getFormRow(['street', 'street2', 'postalCode', 'city', 'state', 'country'], 'mid', 'addressLines')}
        <div className={`row g-0 register_body-add-form-mid mid-last`}>
          <div className="col-lg-6">
            {getPhoneSelect()}
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>{t("email")}*</label>
              <input 
                type="text" 
                className="form-control" 
                value={companyDetails.email.value} 
                name={'email'}
                onChange={e => handleDetailChange(e)} 
              />
            </div>
          </div>
        </div>
        <div className="register_body-add-form-separator" />
        <SectionTitle icon={<UserIcon />} titleKey={"managerDetails"} />
        {getFormRow(['firstName', 'lastName'], 'mid manager', 'manager', 'col-lg-6')}
        <div className={`row g-0 register_body-add-form-bottom`}>
          <div className="col-lg-6">
            <div className="form-group">
              <label>{t("managerEmail")}*</label>
              <input 
                type="text" 
                className="form-control" 
                value={companyDetails.manager.email.value} 
                name={'email'}
                onChange={e => handleDetailChange(e, 'manager')} 
              />
            </div>
          </div>
          <div className="col-lg-6">
            {getPhoneSelect(true)}
          </div>
          </div>
          <div className="register_body-add-footer">
            {
              requiredError ? (
                <div className="position-relative">
                  <div className="register_body-error">
                    <span>{requiredError}</span>
                  </div>
                </div>
              )
              :
              undefined
            }
            <Checkbox checked={agreedTerms} onChange={val => handleTerms(val)} label={<span>{t("addUserTerms")} <a href="/privaatsus-ja-kasutustingimused" target="_blank" className="link">{t("termsLinkText2")}</a></span>} />
            <button onClick={() => saveCompany()} className="btn-dark">
              {t("sendApplication")}
            </button>
          </div>
      </div>
    )
  }

  return (
    getAddForm()
  )
}

export default Register