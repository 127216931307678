import { Provider } from 'mobx-react';
import './App.scss';
import Layout from './Components/Layout';
import AuthStore from './Store/AuthStore';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Login from './Components/Pages/Login';
import ErrorPage from './Components/Pages/ErrorPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import Dashboard from './Components/Pages/Dashboard';
import OrderDetails from './Components/Pages/OrderDetails';
import Catalog from './Components/Pages/Catalog';
import CreateOrder from './Components/Pages/CreateOrder';
import DraftOrders from './Components/Pages/DraftOrders';
import OrderHistory from './Components/Pages/OrderHistory';
import Settings from './Components/Pages/Settings';
import ContentStore from './Store/ContentStore';
import OrderStore from './Store/OrderStore';
import Terms from './Components/Pages/Terms';
import AdminStore from './Store/AdminStore';
import Admin from './Components/Pages/Admin';
import AddCompany from './Components/Pages/AddCompany';
import AdminNotification from './Components/Pages/AdminNotification';
import LimitedCompany from './Components/Pages/LimitedCompany';
import Register from './Components/Pages/Register';
import ToastStore from './Store/ToastStore';
import PublicLayout from './Components/PublicLayout';


function App() {

  const toastStore = new ToastStore()
  const authStore = new AuthStore(toastStore)
  const orderStore = new OrderStore(toastStore, authStore)
  const contentStore = new ContentStore(toastStore, authStore)
  const adminStore = new AdminStore(toastStore, authStore)

  return (
      <Provider
        auth={authStore}
        content={contentStore}
        order={orderStore}
        toast={toastStore}
        admin={adminStore}
      >
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<PublicLayout title={"loginTitle"}><Login /></PublicLayout>} />
            <Route path="/register" element={<PublicLayout title={"registerTitle"}><Register /></PublicLayout>} />
            <Route path="/login/:codeInsert?" element={<PublicLayout title={"loginTitle"}><Login /></PublicLayout>} />
            <Route path="/admin" element={<Layout title={"companies"}><Admin /></Layout>} />
            <Route path="/admin/add" element={<Layout title={"addCompany"}><AddCompany /></Layout>} />
            <Route path="/admin/notification" element={<Layout title={"notifications"}><AdminNotification /></Layout>} />
            <Route path="/dashboard" element={<Layout title={"dashboard"}><Dashboard /></Layout>} />
            <Route path="/dashboard/limited" element={<Layout hideTitle={true}><LimitedCompany /></Layout>} />
            <Route path="/catalog" element={<Layout title={"productCatalog"}><Catalog /></Layout>} />
            <Route path="/order/create/:id?" element={<Layout title={"createOrder"}><CreateOrder /></Layout>} />
            <Route path="/order/history" element={<Layout title={"orderHistory"}><OrderHistory /></Layout>} />
            <Route path="/order/archive/:id" element={<Layout title={"orderOverview"}><OrderDetails /></Layout>} />
            <Route path="/order/drafts" element={<Layout title={"draftOrders"}><DraftOrders /></Layout>} />
            <Route path="/settings" element={<Layout title={"companySettings"}><Settings /></Layout>} />
            <Route path="/privaatsus-ja-kasutustingimused" element={<Terms />} />
          </Routes>
        </BrowserRouter>
      </Provider>
  );
}

export default App;
