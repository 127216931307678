import { MobXProviderContext, observer } from "mobx-react";
import { ReactComponent as FilterIcon } from '../../svg/funnel.svg';
import { ReactComponent as Chevron } from '../../svg/chevron-down.svg';
import { ReactComponent as Search } from '../../svg/search.svg';
import { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import SectionTitle from "./SectionTitle";

const Filters = observer(({ className = 'default' }) => {

  const { content: { 
      catalogFilters: { 
        categoryList, 
        manufacturerList 
      }, 
      filterByManufacturer, 
      filterByCategory, 
      resetCategoryFilter, 
      resetManufacturerFilter,
      filterByTerm,
      orderProducts
    } 
  } = useContext(MobXProviderContext)
  
  const { t } = useTranslation()

  const [sortOptions] = useState([
    {
      name: t("priceDESC"),
      value: 'priceDESC'
    },
    {
      name: t("priceASC"),
      value: 'priceASC'
    }
  ])

  const handleCategoryChange = e => {
    const { value } = e.target
    if(value === 'vali'){
      resetCategoryFilter()
    }else{
      filterByCategory(value)
    }
  }

  const handleManufacturerChange = e => {
    const { value } = e.target
    if(value === 'vali'){
      resetManufacturerFilter()
    }else{
      filterByManufacturer(value)
    }
  }

  const handleProductOrderChange = e => {
    const { value } = e.target
    if(value === 'vali'){

    }else{
      orderProducts(value)
    }
  }

  const handleSearchChange = e => {
    const { value } = e.target
    filterByTerm(value)
  }

  return (
    <div className={`filters ${className}`}>
      <SectionTitle titleKey={'filterProducts'} icon={<FilterIcon />} />
      <div className="filters_body">
        <div className="row">
          <div className="col-md-6 col-lg-4 col-xl-3 col-xs-12">
            <div className="select">
              <select onChange={e => handleCategoryChange(e)} defaultValue={'vali'} className="select_input">
                <option value={'vali'}>{t('chooseCategory')}</option>
                {
                  categoryList.map(item => (
                    <option key={item} value={item}>{item}</option>
                  ))
                }
              </select>
              <Chevron className="select_chevron" />
            </div>
          </div>
          <div className="col-md-6 col-lg-4 col-xl-3 col-xs-12">
            <div className="select">
              <select onChange={e => handleManufacturerChange(e)} defaultValue={'vali'} className="select_input">
                <option value={'vali'}>{t('chooseManu')}</option>
                {
                  manufacturerList.map(item => (
                    <option key={item} value={item}>{item}</option>
                  ))
                }
              </select>
              <Chevron className="select_chevron" />
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mt-md-1 mt-lg-0 col-xl-3 col-xs-12">
            <div className="select">
              <select onChange={e => handleProductOrderChange(e)} defaultValue={'vali'} className="select_input">
                <option value={'vali'}>{t('orderProducts')}</option>
                {
                  sortOptions.map(item => (
                    <option key={item.value} value={item.value}>{item.name}</option>
                  ))
                }
              </select>
              <Chevron className="select_chevron" />
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mt-md-1 mt-xl-0 col-xl-3 col-xs-12">
            <div className="form-group">
              <Search />
              <input onChange={e => handleSearchChange(e)} className="form-control" placeholder={t('searchPlaceholder')} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )

})

export default Filters