import { MobXProviderContext, observer } from "mobx-react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useRef } from "react";

const Toast = observer(() => {

  const prevPath = useRef()
  const { toast: { message, show, error, clearToast } } = useContext(MobXProviderContext)

  const { pathname } = useLocation()

  useEffect(() => {
    if(prevPath.current === null){
      prevPath.current = pathname
      return
    }
    if(pathname !== prevPath.current){
      clearToast()
      prevPath.current = pathname
    }
  }, [pathname])

  if(!show){
    return <div />
  }

  return (
    <div className="custom-toast">
      <div className={`custom-toast_body ${error ? 'error' : 'default'}`}>
        <span className="custom-toast_body-message">{message}</span>
      </div>
    </div>
  )
  
})

export default Toast