import { ReactComponent as Icon } from '../../svg/alert-circle.svg';
import { ReactComponent as IconMain } from '../../svg/alert-circle-main.svg';
import { useTranslation } from 'react-i18next';

const Notice = ({titleKey, bodyKey, className}) => {

  const { t } = useTranslation()

  return (
    <div className={`notice ${className ? className : 'default'}`}>
      <div className='notice_icon'>
        <div className='notice_icon-wrapper'>
          {className === 'light' ? <IconMain /> : <Icon />}
        </div>
      </div>
      <div className='notice_body'>
        <p>{t(titleKey)}</p>
        <span>{t(bodyKey)}</span>
      </div>
    </div>
  )
}

export default Notice