import { MobXProviderContext, observer } from "mobx-react";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ReactComponent as BriefcaseIcon } from '../../svg/briefcase-main.svg';
import { ReactComponent as ClockIcon } from '../../svg/clock-rewind.svg';
import { ReactComponent as FolderIcon } from '../../svg/folder.svg';
import { format } from 'date-fns';
import SectionTitle from "../Partials/SectionTitle";
import Spinner from "../Partials/Spinner";

const Dashboard = observer(() => {

  const { t } = useTranslation()

  const { auth: { companyLoaded, company }, order: { orderHistory, draftOrders, getHistoricalOrders, getDraftOrders } } = useContext(MobXProviderContext)

  useEffect(() => {
    if(companyLoaded){
      getHistoricalOrders(true)
      getDraftOrders(true)
    }
  }, [companyLoaded])

  const getHistory = () => (
    <div className="col-lg-6">
      <Card icon={<ClockIcon />} className={"me-lg-2 me-0"} titleKey={"orderHistory"} footerLink={"/order/history"} footerText={t("viewAll")}>
        <div className="row g-0 table-header">
          <div className="col-6 table-col">
            <label className="contrast-md-text">{t("orderName")}</label>
          </div>
          <div className="col-4 table-col">
            <label className="contrast-md-text">{t("date")}</label>
          </div>
          <div className="col-2 table-col text-right">
            <label className="contrast-md-text">{t("actions")}</label>
          </div>
        </div>
        {
          orderHistory.content.length ? (
            orderHistory.content.slice(0, 4).map(order => {
              const { name, _id, updatedAt } = order
              return (
                <div className="row g-0 table-row" key={_id}>
                  <div className="col-6 table-col">
                    <label>{name}</label>
                  </div>
                  <div className="col-4 table-col">
                    <label>{format(new Date(updatedAt), 'dd.MM.yyyy')}</label>
                  </div>
                  <div className="col-2 table-col text-right">
                    <Link to={`/order/archive/${_id}`} className="link">
                      {t("view")}
                    </Link>
                  </div>
                </div>
              )
            })
          )
          :
          (
            <div className="row g-0 table-row">
              <div className="col-12 table-col">
                <label style={{ marginBottom: 0, lineHeight: '12px' }}>{t("noHistory")}</label>
              </div>
            </div>
          )
        }
      </Card>
    </div>
  )

  const getDrafts = () => (   
    <div className="col-lg-6">
      <Card icon={<FolderIcon />} className={"ms-lg-2 ms-0"} titleKey={"draftOrders"} footerLink={"/order/drafts"} footerText={t("viewAll")}>
        <div className="row g-0 table-header">
          <div className="col-10 table-col">
            <label className="contrast-md-text">{t("orderName")}</label>
          </div>
          <div className="col-2 table-col text-right">
            <label className="contrast-md-text">{t("actions")}</label>
          </div>
        </div>
        {
          draftOrders.content.length ? (
            draftOrders.content.slice(0, 4).map(order => {
              const { name, _id } = order
              return (
                <div className="row g-0 table-row" key={_id}>
                  <div className="col-10 table-col">
                    <label>{name}</label>
                  </div>
                  <div className="col-2 table-col text-right">
                    <Link to={`/order/create/${_id}`} className="link">
                      {t("change")}
                    </Link>
                  </div>
                </div>
              )
            })
          )
          :
          (
            <div className="row g-0 table-row">
              <div className="col-12 table-col">
                <label style={{ marginBottom: 0, lineHeight: '12px' }}>{t("noDrafts")}</label>
              </div>
            </div>
          )
        }
      </Card>
    </div>
  )

  const getContent = () => {
    return (
      <div className="dashboard_body-content-row row g-0">
        {getHistory()}
        {getDrafts()}
      </div>
    )
  }

  return (
    <div className="dashboard">
      <div className="dashboard_body">
        {
          orderHistory.loaded && draftOrders.loaded ? (
            <div className="dashboard_body-content">
              <div className="dashboard_body-content-meta">
                <SectionTitle className="light" icon={<BriefcaseIcon />} titleKey={"representingCompany"} extraText={` ${company.name}`} />
              </div>
              {
                draftOrders.content.length === 0 && orderHistory.content.length === 0 ? (
                  <div className="dashboard_body-content-empty">
                    <p className="mb-0 contrast-lg-text">
                      {t("noHistoryOrDrafts")}
                    </p>
                  </div>
                )
                :
                getContent()
              }
            </div>
          )
          :
          (
            <div className="dashboard_body-loader">
              <Spinner />
            </div>
          )
        }
      </div>
    </div>
  )

})

const Card = ({ titleKey, icon, children, footerLink, footerText, className }) => {

  return (
    <div className={`meta_card card ${className ? className : 'default'}`}>
      <div className="card_header">
        <SectionTitle icon={icon} titleKey={titleKey} />
      </div>
      <div className="card_body">
        {children}
      </div>
      <div className="card_footer">
        <Link className="card_footer-link link" to={footerLink}>{footerText}</Link>
      </div>
    </div>
  )

}

export default Dashboard