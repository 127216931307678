import { MobXProviderContext, observer } from "mobx-react";
import { ReactComponent as Logo } from '../../svg/solider-logo-light.svg'
import { ReactComponent as EditIcon } from '../../svg/edit.svg'
import { ReactComponent as AnnotationIcon } from '../../svg/annotation-check.svg'
import { ReactComponent as BriefcaseIcon } from '../../svg/briefcase.svg';
import { ReactComponent as HomeIcon } from '../../svg/home.svg';
import { ReactComponent as GridIcon } from '../../svg/grid.svg'
import { ReactComponent as BurgerIcon } from '../../svg/burger-light.svg'
import { ReactComponent as ChevronIcon } from '../../svg/chevron-down.svg'
import { ReactComponent as CloseIcon } from '../../svg/x-close-white.svg'
import { ReactComponent as ClockIcon } from '../../svg/clock-rewind.svg'
import { ReactComponent as SettingsIcon } from '../../svg/settings.svg'
import { ReactComponent as FolderIcon } from '../../svg/folder.svg'
import { useTranslation } from 'react-i18next';
import { useLocation, Link, useNavigate } from 'react-router-dom'
import { useContext, useEffect, useState } from "react";
import LanguageSelect from "./LanguageSelect";

const Sidebar = observer(() => {

  const { t } = useTranslation()

  const navigate = useNavigate()
  
  const { auth: { user, company, companyList, logout, lang, changeCompany } } = useContext(MobXProviderContext)

  const [visible, setVisible] = useState(false)
  const [adminLinkList] = useState({
    companies: {
      label: "Ettevõtted",
      list: [
        {
          icon: <BriefcaseIcon />,
          title: "Kõik ettevõtted",
          href: '/admin'
        },
        {
          icon: <EditIcon />,
          title: "Lisa uus ettevõte",
          href: '/admin/add'
        }
      ]
    },
    notifications: {
      label: 'Teavitused',
      list: [
        {
          icon: <AnnotationIcon />,
          title: "Muuda teavitust",
          href: '/admin/notification'
        }
      ]
    }
  })

  const [linkList] = useState({
    catalog: {
      label: t('productCatalogTitle'),
      list: [
        {
          icon: <GridIcon />,
          title: t('productCatalog'),
          href: '/catalog'
        }
      ]
    },
    orders: {
      label: t('orders'),
      list: [
        {
          icon: <EditIcon />,
          title: t('createOrder'),
          href: '/order/create'
        },
        {
          icon: <ClockIcon />,
          title: t('orderHistory'),
          href: '/order/history'
        },
        {
          icon: <FolderIcon />,
          title: t('draftOrders'),
          href: '/order/drafts'
        }
      ]
    },
    settings: {
      label: t('settings'),
      list: [
        {
          icon: <SettingsIcon />,
          title: t('companySettings'),
          href: '/settings'
        },
      ]
    }
  })
  
  const handleLogout = () => {
    localStorage.removeItem('solider-client-side-auth-token')
    logout()
    navigate('/')
  }

  const handleCompanyChange = e => {
    const { value } = e.target
    changeCompany(value)
  }

  const { pathname } = useLocation()

  useEffect(() => {
    setVisible(false)
  }, [pathname])

  const getLinkList = ({ list, label }) => {
    return (
      <div key={label} className="sidebar_body-section">
        <div className="sidebar_body-section-label">
          <label>{label}</label>
        </div>
        {
          list.map((item, index) => {
            const isActive = item.href === pathname
            return (
              <div key={index + item.title} className="sidebar_body-section-list">
                  <Link className="unset" to={item.href}>
                    <div className={`sidebar_body-section-list-item ${isActive ? 'active' : 'default'}`}>
                      <div className="icon-wrapper">
                        {item.icon}
                      </div>
                      <div className="label-wrapper">
                        <p>{item.title}</p>
                      </div>
                    </div>
                  </Link>
              </div>
            )
          })
        }
      </div>
    )
  }

  return (
    <>
      <div className="mobile-toggle">
        <Link className="unset" to={"/dashboard"}>
          <Logo />
        </Link>
        <button onClick={() => setVisible(!visible)} className="btn-none">
          {visible ? <CloseIcon /> : <BurgerIcon />}
        </button>
      </div>
      <div className={`sidebar ${visible ? 'visible' : 'nvisible'}`}>
        <div className="sidebar_content">
          <div className="sidebar_header">
            <Link className="unset" to={"/dashboard"}>
              <Logo />
              <HomeIcon className="sidebar_header-home" />
            </Link>
          </div>
          <div className="sidebar_body">
            <div className="sidebar_body-top">
              <div className="sidebar_body-top-section">
                <label>{t('welcome')}</label>
                <p>{user.firstName}</p>
              </div>
              {
                user.level === 'admin' ? (
                  undefined
                )
                :
                (
                  <div className="sidebar_body-top-section">
                    <label>{t('companyList')}</label>
                    {
                      companyList.length > 1 ? (
                        <div className="form-group">
                        <select onChange={e => handleCompanyChange(e)} className="form-control sidebar_body-top-section-company" value={company._id}>
                          {
                            companyList.map(item => (
                              <option key={item._id} value={item._id}>{item.name}</option>
                            ))
                          }
                        </select> 
                        <ChevronIcon />
                        </div>
                      )
                      :
                      (
                        <p>{company.name}</p>
                      )
                    }
                  </div>
                )
              }
            </div>
            {
              user.level === 'admin' ? 
              (
                <>
                  {getLinkList(adminLinkList.companies)}
                  {getLinkList(adminLinkList.notifications)}
                </>
              )
              :
              (
                <>
                  {getLinkList(linkList.catalog)}
                  {getLinkList(linkList.orders)}
                  {getLinkList(linkList.settings)}
                </>
              )
            }
          </div>
          <div className="sidebar_footer">
            <div className="sidebar_footer-links">
              <Link className="unset link" to="/privaatsus-ja-kasutustingimused">
                {t("terms")}
              </Link>
              <button onClick={() => handleLogout()} className="btn-none unset link" to="/privaatsus-ja-kasutustingimused">
                {t("logOut")}
              </button>
            </div>
            <LanguageSelect className="light" />
            <div className="sidebar_footer-ref">
              <span>©{new Date().getFullYear()} ISB OÜ | <a className="unset" href="https://xysum.ee" target="_blank">Site by XYSUM</a></span>
            </div>
          </div>
        </div>
      </div>
    </>
  )

})

export default Sidebar