import { observer, MobXProviderContext } from "mobx-react";
import { ReactComponent as SearchIcon } from '../../svg/search.svg';
import { useState, useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DateFilter from "../Partials/DateFilter";
import { format } from 'date-fns';
import Pagination from "../Partials/Pagination";
let _debounce = null

const OrderHistory = observer(() => {

  const [closeFilters, setCloseFilters] = useState(false)
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { order: { 
    getHistoricalOrders, 
    duplicateOrder,
    duplicateLoading,
    handleHistoricalFilterChange,
    orderHistory: { 
      loaded, 
      loading, 
      hasMore, 
      page,
      content 
    },  
  }, auth: { companyLoaded } } = useContext(MobXProviderContext)
  const [dateStart, setDateStart] = useState(null)
  const [dateEnd, setDateEnd] = useState(null)
  const [filterQuery, setFilterQuery] = useState('')

  useEffect(() => {
    if(companyLoaded){
      getHistoricalOrders(true)
    }
  }, [companyLoaded])

  const shouldCloseFilters = e => {
    setCloseFilters(!closeFilters)
  }

  const editOrder = id => {
    navigate(`/order/archive/${id}`)
  }
  
  const handlePaginate = next => {
    getHistoricalOrders(false, false, next)
    document.body.scroll(0,0)
  }

  const duplicateOrderAndNavigate = async id => {
    try{
      if(duplicateLoading){
        return
      }
      const { success, draftID } = await duplicateOrder(id)
      if(success){
        navigate(`/order/create/${draftID}`)
      }
    }catch(err){
      console.log(err)
    }
  }

  const resetCalendar = () => {
    setDateStart(null)
    setDateEnd(null)
    handleHistoricalFilterChange(null, null, filterQuery)
  }

  const handleCalendarChange = (start, end) => {
    setDateStart(start)
    setDateEnd(end)
    handleHistoricalFilterChange(start, end, filterQuery)
  }

  const handleSearchChange = e => {
    const { value } = e.target
    setFilterQuery(value)
    clearTimeout(_debounce)
    _debounce = setTimeout(() => {
      console.log('here')
      handleHistoricalFilterChange(dateStart, dateEnd, value)
    }, 750)
  }

  return (
    <div className="orders orders-history" onClick={e => shouldCloseFilters(e)}>
      <div className="orders_header">
        <div className="row g-0">
          <div className="col-lg-8 col-12">
            <div className="orders_header-content">
              <DateFilter 
                onChange={(startDate, endDate) => handleCalendarChange(startDate, endDate)} 
                separator={<span className="filter-separator contrast-md-text">{t("until")}</span>} 
                closeFilters={closeFilters}
                reset={() => resetCalendar()}
              />
            </div>
          </div>
          <div className="col-lg-4 col-12">
            <div className="orders_header-content search-form">
              <div className="form-group">
                <SearchIcon />
                <input onChange={e => handleSearchChange(e)} value={filterQuery} className="form-control" type="text" name="searchQuery" placeholder={t("searchByNameOrNumber")} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="order_body">
        <div className="order-table">
          <div className="order-table_header">
            <div className="row g-0">
              <div className="col-lg-2 order-table_header-left">
                <label>{t("orderNumber")}</label>
              </div>
              <div className="col-lg-3">
                <label>{t("orderName")}</label>
              </div>
              <div className="col-lg-2">
                <label>{t("grossSum")}</label>
              </div>
              <div className="col-lg-2">
                <label>{t("date")}</label>
              </div>
              <div className="col-lg-2">
                <label>{t("actions")}</label>
              </div>
            </div>
          </div>
          <div className="order-table_body">
            {
              !loaded || loading ? (
                <div>
                  <span> loading</span>
                </div>
              )
              :
              (
                content.length === 0 ? (
                  <div className="row g-0 order-table_body-row">
                    <div className="col-12 order-table_body-row-empty">
                      <label className="text-100 md-text">
                        {t("noHistory")}
                      </label>
                    </div>
                  </div>
                )
                :
                (
                  content.map(({ _id, updatedAt, name, invoiceNo, total }) => {
                    return (
                      <div key={_id} className="row g-0 order-table_body-row">
                        <div className="col-lg-2 order-table_body-left">
                          <label className="text-100 md-text">#{invoiceNo}</label>
                        </div>
                        <div className="col-lg-3">
                          <label className="text-100 md-text">{name}</label>
                        </div>
                        <div className="col-lg-2">
                          <label className="text-100 md-text">€{total.toFixed(2)}</label>
                        </div>
                        <div className="col-lg-2">
                          <label className="text-100 md-text">{format(new Date(updatedAt), 'dd.MM.yyyy')}</label>
                        </div>
                        <div className="col-lg-2">
                          <div className="order-table_body-actions">
                            <button onClick={() => navigate(`/order/archive/${_id}`)} className="btn-none btn-0 p-0 underline md-text text-100">
                              {t("orderDetails")}
                            </button>
                            <button onClick={() => duplicateOrderAndNavigate(_id)} className="btn-none btn-0 p-0 ms-3 underline md-text text-100">
                              {t("reorder")}
                            </button>
                          </div>
                        </div>
                      </div>
                    )
                  })
                )
              )
            }
          </div>
        </div>
      </div>
      <div className="order_pagination">
          <Pagination disableNext={!hasMore} disablePrevious={page === 0} handleClick={next => handlePaginate(next)} />
      </div>
    </div>
  )

})

export default OrderHistory