import { makeAutoObservable, configure } from "mobx";
import { fetchProducts, fetchShippingMethods, fetchUpdatedProducts } from "./api";
import prods from './temp_data.json'

configure({
  enforceActions: "never",
})

class ContentStore {
  constructor(toaster, auth){
    makeAutoObservable(this, {}, { autoBind: true })
    this.toaster = toaster
    this.auth = auth
  }

  products = []
  productsLoading = false
  productsFetched = false

  catalogFilters = {
    category: '',
    categoryList: [],
    manufacturer: '',
    manufacturerList: [],
    searchTerm: '',
    sortOrder: ''
  }
  catalogContent = {
    page: 1,
    skip: 20,
    hasMore: true,
    content: [],
    filteredContent: []
  }

  shippingMethods = []
  shippingMethodsLoading = false
  freeShippingThreshold = 300

  async getShippingMethods() {
    try{
      if(this.shippingMethodsLoading){
        return
      }
      this.shippingMethodsLoading = true
      const { methods, freeShipping } = await fetchShippingMethods()
      this.shippingMethods = methods
      this.freeShippingThreshold = freeShipping
    }catch(err){
      console.log(err)
    }finally{
      this.shippingMethodsLoading = false
    }
  }

  async getProductCatalog(){
    try{
      if(this.productsLoading){
        return
      }
      this.productsLoading = true
      const { products } = await fetchProducts(this.auth.company._id)
      this.products = products
      this.productsFetched = true
      this.initCatalogContent()
    }catch(err){
      console.log(err)
    }finally{
      this.productsLoading = false
    }
  }

  async forceUpdateCatalog(){
    try{
      if(this.productsLoading){
        return
      }
      this.productsLoading = true
      const { products } = await fetchUpdatedProducts(this.auth.company._id)
      this.products = products
      this.productsFetched = true
      this.initCatalogContent()
    }catch(err){
      console.log(err)
    }finally{
      this.productsLoading = false
    }
  }

  setProductsFetched(status = true){
    this.productsFetched = status
  }

  initCatalogContent(){
    const categoryList = []
    const manufacturerList = []
    this.products.forEach(product => {
      const { groupName: category, brandName: name } = product
      if(!categoryList.includes(category)){
        categoryList.push(category)
      }
      if(!manufacturerList.includes(name)){
        manufacturerList.push(name)
      }
    })
    this.catalogContent.page = 1
    this.catalogContent.sortOrder = ''
    this.catalogContent.manufacturer = ''
    this.catalogContent.category = ''
    this.catalogContent.searchTerm = ''
    this.catalogContent.filteredContent = this.products
    this.catalogContent.content = this.products.slice(0, this.catalogContent.skip)
    this.catalogFilters.categoryList = categoryList
    this.catalogFilters.manufacturerList = manufacturerList
  }

  orderProducts(value){
    if(value === 'vali'){
      this.catalogContent.sortOrder = ''
      return
    }
    if(value === 'priceASC'){
      this.catalogContent.filteredContent = this.catalogContent.filteredContent.sort((a, b) => Number(a.priceListPrice) - Number(b.priceListPrice))
    }
    if(value === 'priceDESC'){
      this.catalogContent.filteredContent = this.catalogContent.filteredContent.sort((b, a) => Number(a.priceListPrice) - Number(b.priceListPrice))
    }
    this.catalogContent.content = this.catalogContent.filteredContent.slice(0, this.catalogContent.skip)
    this.setParamsAfterFilter()
    this.catalogContent.sortOrder = value
  }

  resetCategoryFilter(){
    let fullList = [...this.products]
    fullList = this.checkFilterOverlap(fullList, 'category')
    this.catalogContent.filteredContent = fullList
    this.catalogContent.content = fullList.slice(0, this.catalogContent.skip)
    this.catalogContent.category = ''
    this.setParamsAfterFilter()
    if(this.catalogContent.sortOrder){
      this.orderProducts(this.catalogContent.sortOrder)
    }
  }

  checkFilterOverlap(list, ignoreKey){
    let fullList = [...list]
    if(this.catalogContent.category && ignoreKey !== 'category'){
      fullList = fullList.filter(({ groupName }) => groupName.toLowerCase() === this.catalogContent.category.toLowerCase())
    }
    if(this.catalogContent.manufacturer && ignoreKey !== 'manufacturer'){
      fullList = fullList.filter(({ brandName }) => brandName.toLowerCase() === this.catalogContent.manufacturer.toLowerCase())
    }
    if(this.catalogContent.searchTerm && ignoreKey !== 'searchTerm'){
      //do some filter magic
      const copy = [...fullList]
      const filtered = []
      const regex = new RegExp(this.catalogContent.searchTerm, 'ig')
      copy.forEach((product) => {
        const { code, name } = product
        if(regex.test(code) || regex.test(name)){
          filtered.push(product)
        }
      })
      fullList = [...filtered]
    }
    return fullList
  }

  filterByTerm(value){
    if(value === ''){
      let fullList = [...this.products]
      fullList = this.checkFilterOverlap(fullList, 'searchTerm')
      this.catalogContent.filteredContent = fullList
      this.catalogContent.content = fullList.slice(0, this.catalogContent.skip)
      this.catalogContent.searchTerm = ''
      this.setParamsAfterFilter()
    }else{
      let fullList = []
      const regex = new RegExp(value, 'ig')
      this.products.forEach((product) => {
        const { code, name } = product
        if(regex.test(code) || regex.test(name)){
          fullList.push(product)
        }
      })
      fullList = this.checkFilterOverlap(fullList, 'searchTerm')
      this.catalogContent.filteredContent = fullList
      this.catalogContent.content = fullList.slice(0, this.catalogContent.skip)
      this.catalogContent.searchTerm = value
      this.setParamsAfterFilter()
    }
    if(this.catalogContent.sortOrder){
      this.orderProducts(this.catalogContent.sortOrder)
    }
  }

  resetManufacturerFilter(){
    let fullList = [...this.products]
    fullList = this.checkFilterOverlap(fullList, 'manufacturer')
    this.catalogContent.filteredContent = fullList
    this.catalogContent.content = fullList.slice(0, this.catalogContent.skip)
    this.catalogContent.manufacturer = ''
    this.setParamsAfterFilter()
    if(this.catalogContent.sortOrder){
      this.orderProducts(this.catalogContent.sortOrder)
    }
  }

  filterByCategory(category){
    let fullList = this.products.filter(({ groupName }) => groupName.toLowerCase() === category.toLowerCase())
    fullList = this.checkFilterOverlap(fullList, 'category')
    this.catalogContent.filteredContent = fullList
    this.catalogContent.content = fullList.slice(0, this.catalogContent.skip)
    this.catalogContent.category = category.toLowerCase()
    this.setParamsAfterFilter()
    if(this.catalogContent.sortOrder){
      this.orderProducts(this.catalogContent.sortOrder)
    }
  }

  filterByManufacturer(manufacturer){
    let fullList = this.products.filter(({ brandName }) => brandName.toLowerCase() === manufacturer.toLowerCase())
    fullList = this.checkFilterOverlap(fullList, 'manufacturer')
    this.catalogContent.filteredContent = fullList
    this.catalogContent.content = fullList.slice(0, this.catalogContent.skip)
    this.catalogContent.manufacturer = manufacturer.toLowerCase()
    this.setParamsAfterFilter()
    if(this.catalogContent.sortOrder){
      this.orderProducts(this.catalogContent.sortOrder)
    }
  }

  setParamsAfterFilter(){
    this.catalogContent.hasMore = this.catalogContent.filteredContent.length > this.catalogContent.skip
    this.catalogContent.page = 1
  }

  paginateCatalogContent(next = true){
    const { skip, page, hasMore, content, filteredContent } = this.catalogContent
    if(next){
      if(!hasMore){
        console.log('end of content at page ' + page)
        return
      }
      const nextPage = page + 1
      const contentStart = page * skip
      const contentEnd = nextPage * skip
      this.catalogContent.content = filteredContent.slice(contentStart, contentEnd)
      this.catalogContent.page = nextPage
      this.catalogContent.hasMore = filteredContent.length > contentEnd
    }else{
      const end = (skip * page) - skip
      const start = (end - skip)
      if(start < 0){
        return
      }
      const previousPage = start === 0 ? 1 : page - 1
      this.catalogContent.content = filteredContent.slice(start, end)
      this.catalogContent.page = previousPage
    }
  }

}

export default ContentStore