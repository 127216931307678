import { MobXProviderContext, observer } from "mobx-react";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { adminCompanyCheck, postAdminNewCompany } from "../../Store/api";
import { ReactComponent as BriefcaseIcon } from '../../svg/briefcase.svg';
import { ReactComponent as Chevron } from '../../svg/chevron-down.svg';
import SectionTitle from "../Partials/SectionTitle";

const AddCompany = observer(() => {

  const { auth } = useContext(MobXProviderContext)
  const navigate = useNavigate()

  const [regCode, setRegCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [requiredError, setRequiredError] = useState(null)
  const [phoneExt, setPhoneExt] = useState([
    {
      value: '+372',
      selected: true
    },
    {
      value: '+371',
      selected: false,
    },
    {
      value: '+358',
      selected: false
    }
  ])
  const [managerPhoneExt, setManagerPhoneExt] = useState([
    {
      value: '+372',
      selected: true
    },
    {
      value: '+371',
      selected: false,
    },
    {
      value: '+358',
      selected: false
    }
  ])
  const [companyDetails, setCompanyDetails] = useState({
    fullName: {
      label: 'Ettevõtte nimi',
      value: "",
      required: true
    },
    registryCode: {
      label: 'Ettevõtte registrikood',
      value: "",
      required: true
    },
    vatNumber: {
      label: 'KMKR',
      value: ""
    },
    addressLines: {
      street: {
        label: 'Tänav, maja',
        value: "",
        required: true
      },
      street2: {
        label: 'Korteri number',
        value: ""
      },
      state: {
        label: 'Maakond',
        value: "",
        required: true
      },
      city: {
        label: 'Linn',
        value: "",
        required: true
      },
      postalCode: {
        label: "Postiindeks",
        value: "",
        required: true
      },
      country: {
        label: "Riik",
        value: "",
        required: true,
        select: true,
        options: ['Eesti', 'Soome', "Läti"]
      }
    },
    contactNumber: {
      label: 'Telefoninumber',
      value: "",
      required: true
    },
    email: {
      label: 'E-posti aadress',
      value: "",
      required: true
    },
    manager: {
      firstName: {
        label: 'Peakasutaja eesnimi',
        value: "",
        required: true
      },
      lastName: {
        label: "Peakasutaja perekonnanimi",
        value: '',
        required: true
      },
      email: {
        label: "Peakasutaja e-posti aadress",
        value: '',
        required: true
      },
      number: {
        label: "Peakasutaja telefoninumber",
        value: '',
        required: true
      },
    }
  })
  const [manual, setManual] = useState(false)
  const [companyLoaded, setCompanyLoaded] = useState(false)
  const [existsError, setExistsError] = useState(false)

  const syncFields = (company) => {
    const copy = {...companyDetails}
    Object.keys(company).forEach(key => {
      if(key === 'addressLines'){
        Object.keys(company.addressLines).forEach(lineKey => {
          copy.addressLines[lineKey].value = company.addressLines[lineKey]
        })
      }else{
        if(key === 'contactNumber'){
          const extCopy = [...phoneExt]
          extCopy.forEach(ext => {
            if(company[key].includes(ext.value)){
              const phoneValue = company[key].replace(ext.value, '')
              copy[key].value = phoneValue
              ext.selected = true
            }else{
              ext.selected = false
            }
          })
          if(company[key].length === 0){
            const item = extCopy.find(({ value }) => value === '+372')
            item.selected = true
          }
          setPhoneExt(extCopy)
        }else{
          if(copy[key]){
            copy[key].value = company[key]
          }
        }
      }
    })
    setCompanyDetails(copy)
  }

  const initFields = () => {
    const copy = {...companyDetails}
    Object.keys(copy).forEach(key => {
      if(key === 'addressLines'){
        Object.keys(copy.addressLines).forEach(lineKey => {
          //console.log(copy.addressLines[lineKey].value)
          if(lineKey === 'country'){
            copy.addressLines[lineKey].value = 'Eesti'
          }else{
            copy.addressLines[lineKey].value = ''
          }
        })
      } else if (key === 'manager'){
        Object.keys(companyDetails.manager).forEach(manKey => {
          copy.manager[manKey].value = ''
        })
      }else{
        copy[key].value = ''
      }
    })
    console.log(copy)
    setCompanyDetails(copy)
  }

  const checkCompany = async () => {
    try{
      setLoading(true)
      const { company, manual } = await adminCompanyCheck(regCode)
      setManual(manual)
      if(manual){
        setExistsError(`Ettevõtet ei leitud ERPLY'st. Loo ettevõte siin ja saada andmed ERPLY'sse.`)
        initFields()
      }else{
        setExistsError(false)
        syncFields(company)
      }
      setCompanyLoaded(true)
    }catch(err){
      if(err.response.data.exists){
        setExistsError(err.response.data.message)
        setCompanyLoaded(false)
        initFields()
      }
    }finally{
      setLoading(true)
    }
  }

  const handleDetailChange = (e, manager) => {
    setRequiredError(null)
    const { name, value } = e.target
    const copy = {...companyDetails}
    if(manager){
      copy.manager[name].value = value
    }else{
      copy[name].value = value
    }
    setCompanyDetails(copy)
  }

  const handleNestedDetailChange = (e, nestedKey) => {
    setRequiredError(null)
    const { name, value } = e.target
    const copy = {...companyDetails}
    copy[nestedKey][name].value = value
    setCompanyDetails(copy)
  }

  const getFormRow = (fields, className, nestedKey = null, colClass = 'col-lg-4') => {
    const targetObject = nestedKey ? companyDetails[nestedKey] : companyDetails
    return (
      <div className={`row g-0 admin_body-add-form-${className}`}>
        {
          fields.map(fieldname => (
            <div key={fieldname} className={colClass}>
              <div className="form-group">
                <label>{targetObject[fieldname].label}{targetObject[fieldname].required ? '*' : ''}</label>
                {
                  targetObject[fieldname].select ? (
                    <>
                      <select className="form-control" value={targetObject[fieldname].value} name={fieldname} onChange={e => nestedKey ? handleNestedDetailChange(e, nestedKey) : handleDetailChange(e)}>
                        {
                          targetObject[fieldname].options.map(option => (
                            <option value={option} key={option}>{option}</option>
                          ))
                        }
                      </select>
                      <Chevron />
                    </>
                  )
                  :
                  (
                    <input type="text" className="form-control" value={targetObject[fieldname].value} name={fieldname} onChange={e => nestedKey ? handleNestedDetailChange(e, nestedKey) : handleDetailChange(e)} />
                  )
                }
              </div>
            </div>
          ))
        }
      </div>
    )
  }

  const handlePhoneChange = (e, extension, manager = false) => {
    const { value } = e.target
    if(extension){
      const copy = manager ? [...managerPhoneExt] : [...phoneExt]
      copy.forEach(item => {
        if(item.value !== value){
          item.selected = false
        }else{
          item.selected = true
        }
      })
      if(manager){
        setManagerPhoneExt(copy)
      }else{
        setPhoneExt(copy) 
      }
    }else{
      setRequiredError(null)
      const { name, value } = e.target
      const copy = {...companyDetails}
      console.log(copy.manager.number)
      if(manager){
        copy.manager.number.value = value
      }else{
        copy.contactNumber.value = value
      }
      setCompanyDetails(copy)
    }
  }

  const getPhoneSelect = (manager = false) => {
    const baseObject = manager ? managerPhoneExt : phoneExt
    const targetObject = manager ? companyDetails.manager : companyDetails.contactNumber
    const activeOption = baseObject.find(({ selected }) => selected === true)
    return (
      <div className="form-group">
        <label>{manager ? 'Peakasutaja ' : ''}Telefoninumber*</label>
          <div className="phone-input">
            <select className="phone-input_select" value={activeOption ? activeOption.value : '+372'} onChange={e => handlePhoneChange(e, true, manager)}>
              {
                baseObject.map(ext => (
                  <option value={ext.value} key={ext.value}>{ext.value}</option>
                ))
              }
            </select>
            <input className="form-control phone-input_number" type="text" value={targetObject.value} onChange={e => handlePhoneChange(e, false, manager)} />
          </div>
      </div>
    )
  }

  const saveCompany = async () => {
    //check required fields show error if needed or send to server
    let missingError = null
    const body = {
      company: {
        addressLines: {}
      },
      manager: {}
    }
    Object.keys(companyDetails.addressLines).forEach(key => {
      const item = companyDetails.addressLines[key]
      if(item.required && item.value === ''){
        missingError = `Kohustuslik väli "${item.label}" täitmata`
      }
      body.company.addressLines[key] = item.value
    })
    Object.keys(companyDetails).forEach(key => {
      if(key === 'addressLines' || key === 'manager'){
        return
      }
      const item = companyDetails[key]
      if(item.required && item.value === ''){
        missingError = `Kohustuslik väli "${item.label}" täitmata`
      }
      body.company[key] = item.value
    })
    Object.keys(companyDetails.manager).forEach(key => {
      const item = companyDetails.manager[key]
      if(item.required && item.value === ''){
        missingError = `Kohustuslik väli "${item.label}" täitmata`
      }
      body.manager[key] = item.value
    })
    if(missingError){
      setRequiredError(missingError)
    }else{
      let contactNumber = companyDetails.contactNumber
      let managerNumber = companyDetails.manager.number
      console.log(companyDetails)
      phoneExt.forEach(({ value, selected }) => {
        if(selected){
          contactNumber = `${value}${companyDetails.contactNumber.value}`
        }
      })
      managerPhoneExt.forEach(({ value, selected }) => {
        if(selected){
          managerNumber = `${value}${companyDetails.manager.number.value}`
        }
      })
      body.company.contactNumber = contactNumber
      body.manager.number = managerNumber
      body.manual = manual
      try{
        const newCompany = await postAdminNewCompany(body)
        console.log(newCompany)
        navigate('/admin')
      }catch(err){
        console.log(err)
      }
    }
  }

  const getAddForm = () => {
    return (
      <div className="admin_body-add-form">
        <SectionTitle icon={<BriefcaseIcon />} className={"light"} titleKey={"companyDetails"} />
        {getFormRow(['fullName', 'registryCode', 'vatNumber'], 'top')}
        {getFormRow(['street', 'street2', 'postalCode', 'city', 'state', 'country'], 'mid', 'addressLines')}
        <div className={`row g-0 admin_body-add-form-mid`}>
          <div className="col-lg-6">
            {getPhoneSelect()}
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>E-posti aadress*</label>
              <input 
                type="text" 
                className="form-control" 
                value={companyDetails.email.value} 
                name={'email'}
                onChange={e => handleDetailChange(e)} 
              />
            </div>
          </div>
        </div>
        {getFormRow(['firstName', 'lastName'], 'mid manager', 'manager', 'col-lg-6')}
        <div className={`row g-0 admin_body-add-form-bottom`}>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Peakasutaja e-posti aadress*</label>
              <input 
                type="text" 
                className="form-control" 
                value={companyDetails.manager.email.value} 
                name={'email'}
                onChange={e => handleDetailChange(e, 'manager')} 
              />
            </div>
          </div>
          <div className="col-lg-6">
            {getPhoneSelect(true)}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="admin">
      <div className="admin_header">
        <div className="admin_header-lookup">
          <div className="form-group">
            <label className="heavier">Registrikood*</label>
            <input type="text" className="form-control" value={regCode} onChange={e => setRegCode(e.target.value)} />
          </div>
          <button onClick={() => checkCompany()} className="btn-dark">
            Otsi Erplyst
          </button>
        </div>
      </div>
      <div className="admin_body">
        {
          !companyLoaded && existsError ? (
            <div className="admin_body-add">
              <div className="admin_body-add-header">
                <span className="contrast-lg-text">{existsError}</span>
              </div>
            </div>
          )
          :
          undefined
          }
        {
          companyLoaded ? (
            <div className="admin_body-add">
              {
                existsError ? (
                  <div className="admin_body-add-header">
                    <span className="contrast-lg-text">{existsError}</span>
                  </div>
                )
                :
                undefined
              }
              {getAddForm()}
              <div className="admin_body-add-footer">
                {
                  requiredError ? (
                    <div className="position-relative">
                      <div className="admin_body-error">
                        <span>{requiredError}</span>
                      </div>
                    </div>
                  )
                  :
                  undefined
                }
                <button onClick={() => saveCompany()} className="btn-dark">
                  Saada Erply'sse
                </button>
              </div>
            </div>
          )
          :
          (
            undefined
          )
        }
      </div>
    </div>
  )

})

export default AddCompany