import { MobXProviderContext, observer } from "mobx-react";
import { useContext, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Sidebar from "./Partials/Sidebar";
import Notification from "./Partials/Notification";
import 'react-calendar/dist/Calendar.css';
import Toast from "./Partials/Toast";

const Layout = observer(({ title, children, hideTitle }) => {

  const { 
    auth: { 
      companyLoaded, getCompanyDetails, detailsLoading, user, company, handleUserLangSelect
    }
  } = useContext(MobXProviderContext)

  const { pathname } = useLocation()

  const { i18n, t } = useTranslation()

  useEffect(() => {
    handleUserLangSelect(i18n.language)
  }, [i18n.language])

  const navigate = useNavigate()

  const hideButton = ['/order/create'].includes(pathname)

  useEffect(() => {
    if(!companyLoaded && !detailsLoading && user.level !== 'admin'){
      getCompanyDetails(true)
    }
  }, [user, companyLoaded])

  useEffect(() => {
    document.title = `${t(title)} | ISB Tellimiskeskus`
    if(companyLoaded && company._id && !company.canOrder && pathname !== '/dashboard/limited'){
      navigate('/dashboard/limited')
    }
  }, [user, companyLoaded, pathname])

  if(!companyLoaded || detailsLoading){
    return (
      <div />
    )
  }



  //Enable to make everything wrapped by layout component to be protected
  if(!detailsLoading && !user._id){
    return navigate('/login')
  }

  return (
    <div className="layout">
      <Sidebar />
      <div className="layout_content">
        <Toast />
        <Notification className="light" />
        {
          hideTitle ? undefined : (
            <div className="layout_content-header">
              <h1 className="mb-0">{t(title)}</h1>
              {
                user.level === 'admin' ? (
                  undefined
                )
                :
                (
                  <button onClick={() => navigate('/order/create')} className="btn-none header-btn" style={{ display: hideButton ? 'none' : 'block' }}>
                    {t('createOrder')}
                  </button>
                )
              }
            </div>
          )
        }
        {children}
      </div>
    </div>
  )

})

export default Layout