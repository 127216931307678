import Calendar from 'react-calendar';
import { ReactComponent as CalendarIcon } from '../../svg/calendar.svg'
import { ReactComponent as ClockIcon } from '../../svg/clock-rewind-main.svg'
import { format } from 'date-fns'
import { forwardRef, useEffect, useState } from 'react';


const comp = props => {}

const DateFilter = ({ reset, onChange, separator, closeFilters }) => {

  const [dateStart, setDateStart] = useState(new Date())
  const [dateStartOpen, setDateStartOpen] = useState(false)
  const [dateEndOpen, setDateEndOpen] = useState(false)
  const [dateEnd, setDateEnd] = useState(new Date())

  useEffect(() => {
    setDateStartOpen(false)
    setDateEndOpen(false)
  }, [closeFilters])

  const handleChange = (value, start = false) => {
    if(start){
      setDateStart(value)
      onChange(format(value, 'MM.dd.yyyy'), format(dateEnd, 'MM.dd.yyyy'))
    }else{
      setDateEnd(value)
      onChange(format(dateStart, 'MM.dd.yyyy'), format(value, 'MM.dd.yyyy'))
    }
  }

  const handleReset = () => {
    setDateEnd(new Date())
    setDateStart(new Date())
    reset()
  }

  const toggleCalendar = (state, start = false) => {
    if(start){
      setDateEndOpen(false)
      setDateStartOpen(state)
    }else{
      setDateStartOpen(false)
      setDateEndOpen(state)
    }
  }

  const prevent = e => {
    e.stopPropagation()
    e.preventDefault()
  }

  return (
    <div className="date-filter">
      <div className="row g-0">
        <div className="col-md-8 col-12 position-relative">
          <div onClick={e => prevent(e)} className='date-filter_wrapper'>
            <div className='date-filter_start'>
              <button onClick={() => toggleCalendar(!dateStartOpen, true)} className="btn-none g-0 ps-0">
                <div className="date-filter_input left">
                  <CalendarIcon />
                  <span>{format(dateStart, 'dd.MM.yyyy')}</span>
                </div>
              </button>
              {
                dateStartOpen ? (
                  <Calendar value={dateStart} onChange={value => handleChange(value, true)} />
                )
                :
                undefined
              }
            </div>
            {separator}
            <div className='date-filter_end'>
              <button onClick={() => toggleCalendar(!dateEndOpen, false)} className="btn-none g-0">
                <div className="date-filter_input left">
                  <CalendarIcon />
                  <span>{format(dateEnd, 'dd.MM.yyyy')}</span>
                </div>
              </button>
              {
                dateEndOpen ? (
                  <Calendar value={dateEnd} onChange={value => handleChange(value)} />
                )
                :
                undefined
              }
            </div>
            <button onClick={() => handleReset()} className='btn-none main-btn'>
              <ClockIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DateFilter