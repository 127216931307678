import { MobXProviderContext, observer } from "mobx-react";
import { useContext, useEffect, useState } from "react";
import { ReactComponent as Logo } from '../../svg/solider-logo.svg';
import { ReactComponent as UserLogo } from '../../svg/user-circle.svg';
import { useTranslation } from 'react-i18next';
import { useParams, Link, useNavigate, use } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import LanguageSelect from "../Partials/LanguageSelect";
import Banner from "../Partials/Banner";

const Login = observer(() => {

  const { t, i18n } = useTranslation()
  const [codeSent, setCodeSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')

  const { auth: { getLoginCode, confirmLogin, detailsLoading, user, companyLoaded, getCompanyDetails, handleUserLangSelect } } = useContext(MobXProviderContext)

  useEffect(() => {
    handleUserLangSelect(i18n.language)
  }, [i18n.language])

  const params = useParams()
  
  const navigate = useNavigate()

  useEffect(() => {
    if(!detailsLoading && !companyLoaded){
      getCompanyDetails()
    }
    if(params.codeInsert){
      setCodeSent(true)
    }
  }, [params])

  useEffect(() => {
    if(companyLoaded && user && user._id && user.level !== 'admin'){
      navigate('/dashboard')
    }
  }, [companyLoaded])

  const handleLoginChange = e => {
    const { value } = e.target
    setEmail(value)
  }

  const handleLogin = async () => {
    try{
      setError(false)
      const { error, message, status } = await getLoginCode(email, 'et')
      if(error){
        setError(message)
        setTimeout(() => {
          setError(false)
        }, 8000)
      }
      if(status){
        setCodeSent(true)
      }
    }catch(err){
      return false
    }
  }

  const checkInput = e => {
    if(e.keyCode === 13){
      handleLogin()
    }
  }

  const getOTPInput = props => {
    const inputProps = {...props, placeholder: '0'}
    return (
      <input {...inputProps} />
    )
  }

  const confirmLoginCode = async () => {
    try{
      const { error, status, message, user } = await confirmLogin(otp)
      if(!error){
        if(user.level === 'admin'){
          navigate('/admin')
        }else{
          navigate('/dashboard')
        }
      }else{
        setError(message)
      }
    }catch(err){
      console.log(err)
    }
  }

  return (
    <div className="login page">
      <div className="row g-0 min-vh-100">
        <div className="col-lg-6 col-md-12 min-vh-100 login-section">
          <div className="login_card">
              <div className="login_card-header">
                <Logo />
                <LanguageSelect />
              </div>
              <div className="login_card-body">
                <div className="text-group">
                  <h1 className="mb-3">{t('loginTitle')}</h1>
                  <p>{t(codeSent ? "loginCodeSubtitle" : "loginWelcome")}</p>
                </div>
                {
                  codeSent ? (
                    <div className="login_card-body-otp">
                      <label>{t("enterCode")}</label>
                      <OtpInput 
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        renderSeparator={<span></span>}
                        containerStyle="otp-container"
                        inputStyle="otp-container_input"
                        renderInput={props => getOTPInput(props)}
                      />
                      <button onClick={() => confirmLoginCode()} className="btn-dark mt-4">
                        {t("login")}
                      </button>
                      {
                        error ? (
                          <div className="position-relative">
                            <div className="login_card-body-error">
                              <span>{error}</span>
                            </div>
                          </div>
                        ) : undefined
                      }
                    </div>
                  )
                  :
                  (
                    <>
                      <div className="form-group">
                        <label>{t("email")}</label>
                        <input onKeyUp={e => checkInput(e)} onChange={e => handleLoginChange(e)} type="email" name="email" className="form-control" placeholder="email@example.com" />
                      </div>
                      <button onClick={() => handleLogin()} className="btn-dark mt-4">
                        {t("login")}
                      </button>
                      {
                        error ? (
                          <div className="position-relative">
                            <div className="login_card-body-error">
                              <span>{error}</span>
                            </div>
                          </div>
                        ) : undefined
                      }
                    </>
                  )
                }
              </div>
              <div className="login_card-footer">
                {
                  codeSent ? (
                    undefined
                  )
                  :
                  (
                    <Banner 
                      icon={<UserLogo />}
                      className={'login_card-banner main'}
                      title={'Sul ei ole veel kontot?'} 
                      subtitle={'Konto loomiseks täida registreerimisvorm.'}
                      buttonText={'Alusta konto loomisega'}
                    />
                  )
                }
              </div>
              <div className="login_card-link">
                <Link className="link" to="/privaatsus-ja-kasutustingimused">
                  {t("termsLinkText")}
                </Link>
              </div>
            </div>
        </div>
        <div className="col-lg-6 d-lg-block d-none col-md-0 min-vh-100">
        <span className="ref">©{new Date().getFullYear()} ISB OÜ | <a className="unset" href="https://xysum.ee" target="_blank">Site by XYSUM</a></span>
        </div>
      </div>
    </div>
  )

})

export default Login