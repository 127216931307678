import { MobXProviderContext, observer } from "mobx-react";
import { useContext } from "react";

const Notification = observer(() => {

  const { auth: { lang, notification: { details, dismissed, loaded } } } = useContext(MobXProviderContext)
  
  if(!loaded || dismissed || !details){
    return <div style={{ display: 'none' }} />
  }

  const titleKey = `title${lang.toUpperCase()}`
  const msgKey = `message${lang.toUpperCase()}`

  const fieldsExists = details[titleKey] && details[msgKey]
  if(!fieldsExists){
    return <div style={{ display: 'none' }} />
  }

  return (
    <div className="notification">
      <div className="notification_body">
        <span className="notification_body-title">
          {details[titleKey]}
        </span>
        <span className="notification_body-message">
          {details[msgKey]}
        </span>
      </div>
    </div>
  )

})

export default Notification