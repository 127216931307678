import { makeAutoObservable, configure, has } from "mobx";

configure({
  enforceActions: "never",
})

let _time = null

class ToastStore {
  constructor(){
    makeAutoObservable(this, {}, { autoBind: true })
  }
  error = false
  message = ''
  show = false

  clearToast(){
    clearTimeout(_time)
    this.error = false
    this.message = ''
    this.show = false
  }

  setToast(messageText, error = false, timeout = 5000){
    this.clearToast()
    this.message = messageText
    this.error = error
    this.show = true
    _time = setTimeout(() => {
      this.error = false
      this.message = ''
      this.show = false
    }, timeout)
  }

}

export default ToastStore