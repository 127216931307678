import { MobXProviderContext, observer } from "mobx-react";
import { ReactComponent as Globe } from '../../svg/globe.svg'
import { ReactComponent as GlobeLight } from '../../svg/globe-light.svg'
import { useTranslation } from 'react-i18next';
import { useContext } from "react";

const LanguageSelect = observer(({ className }) => {

  const { auth: { lang, langOptions, handleUserLangSelect } } = useContext(MobXProviderContext)

  const { t, i18n } = useTranslation()

  const changeLanguage = (code) => {
    handleUserLangSelect(code)
    i18n.changeLanguage(code)
  }

  return (
    <div className={`lang ${className ? className : 'default'}`}>
      <div className="lang_list">
        {className === 'light' ? <GlobeLight /> : <Globe />}
        {
          langOptions.map(option => {
            return (
              <button key={option.code} onClick={() => changeLanguage(option.code)} className={`btn-none lang_list-option ${lang === option.code ? 'active' : 'inactive'}`}>
                <span>{option.code}</span>
              </button>
            )
          })
        }
      </div>
    </div>
  )

})

export default LanguageSelect