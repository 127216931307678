import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import Spinner from './Spinner';

const LoadingScreen = observer(({ admin }) => {

  const { t } = useTranslation()

  return (
    <div className='loading-screen'>
      <div className='loading-screen_content'>
        <Spinner />
        <span className='loading-screen_content-text contrast-lg-text'>{admin ? "Laeme ettevõtteid" : t("loadingText")}</span>
      </div>
    </div>
  )

})

export default LoadingScreen