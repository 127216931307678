import { ReactComponent as Chevron } from '../../svg/chevron-right.svg';
import { useTranslation } from 'react-i18next';

const Pagination = ({ handleClick, disableNext, disablePrevious, className }) => {

  const { t } = useTranslation()

  const onClick = next => {
    if(next && disableNext){
      return
    }
    if(!next && disablePrevious){
      return
    }
    handleClick(next)
  }
  
  return (
    <div className={`pagination ${className ? className : 'default'}`}>
        <div className="pagination-wrapper">
          <button onClick={() => onClick(false)} className={`btn-none catalog_pagination-button pagination-btn pagination-btn-prev ${disablePrevious ? 'disabled' : 'default'}`}>
            <Chevron className="pagination-btn_chevron-left"/>
            {t('prevPage')}
          </button>
          <button onClick={() => onClick(true)} className={`btn-none catalog_pagination-button pagination-btn pagination-btn-next ${disableNext ? 'disabled' : 'default'}`}>
            {t('nextPage')}
            <Chevron className="pagination-btn_chevron-right" />
          </button>
        </div>
    </div>
  )

}

export default Pagination