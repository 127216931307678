import { MobXProviderContext, observer } from "mobx-react";
import Filters from "../Partials/Filters";
import { ReactComponent as Close } from '../../svg/x-close.svg';
import { ReactComponent as Plus } from '../../svg/plus-circle.svg';
import { useContext, useEffect, useRef, useState } from "react";
import { ReactComponent as FileIcon } from '../../svg/file-check.svg'
import { useTranslation } from 'react-i18next';
import Pagination from "../Partials/Pagination";
import CatalogRow from "../Partials/CatalogRow";
import LoadingScreen from "../Partials/LoadingScreen";
import Spinner from "../Partials/Spinner";
import { putProductToDraftOrder } from "../../Store/api";

const Catalog = observer(() => {

  const { content: { 
    catalogContent,
    paginateCatalogContent, 
    getProductCatalog,
    productsLoading,
    productsFetched
  }, 
  toast: {
    setToast
  },
  auth: { 
    showTaxRow, 
    companyLoaded
  },
  order: {
    getDraftOrders,
    draftOrders
  } } = useContext(MobXProviderContext)

  const { t, i18n } = useTranslation()

  const [modal, setModal] = useState(false)
  const [detailProduct, setDetailProduct] = useState(null)
  const [draftDropdown, setDraftDropdown] = useState(false)
  const [draftProduct, setDraftProduct] = useState(null)
  const [selectedImage, setSelectedImage] = useState(null)
  const modalRef = useRef()

  useEffect(() => {
    if(companyLoaded && !productsFetched && !productsLoading){
      getProductCatalog()
    }
  }, [companyLoaded, productsFetched])

  useEffect(() => {
    if(draftOrders.content.length === 0 && !draftOrders.loading){
      getDraftOrders(true)
    }
  }, [draftDropdown])

  const handleDetailClick = product => {
    setDetailProduct(product)
    setSelectedImage(product.relatedFiles.length ? product.relatedFiles[0].fileURL : '/media/placeholder.avif')
    setModal(true)
  }

  const displayDrafts = product => {
    setDraftProduct(product)
    setDraftDropdown(true)
  }

  const hideDrafts = () => {
    setDraftDropdown(false)
    setDraftProduct(null)
  }

  const addToOrder = async (order) => {
    const body = {
      product: {...draftProduct, amount: 1},
      _id: order._id
    }
    try{
      const data = await putProductToDraftOrder(body, i18n.language)
      if(data.updated){
        setDraftDropdown(false)
        setDraftProduct(null)
        setToast(`${t("productAddSuccess")} ${order.name}`, false, 5000)
      }else{
        setToast(`${t("productAddError")}`, true, 5000)
      }
    }catch(err){
      console.log(err)
      //show error message
    }
  }

  const handleDraftContentClick = e => {
    e.preventDefault()
    e.stopPropagation()
  }

  const getPageContent = () => {
    return (
      <div className="catalog_content-rows">
        <div className="row g-0 justify-content-center align-items-center">
          {
            catalogContent.content.map(item => {
              const product = {
                ...item
              }
              const props = { 
                ...item,
                product: item,
                displayVat: showTaxRow,
                showDetailButton: true, 
                actionClass: 'col-lg-2 col-md-3', 
                metaClass: 'col-lg-9 col-md-3',
                showDetails: () => handleDetailClick(product),
                addToOrder: () => displayDrafts(product)
              }
              return (
                <div key={item.productID} className="catalog_content-rows-col col-12">
                  <div className="catalog_content-wrapper">
                    <CatalogRow {...props} />
                    {
                      draftDropdown && draftProduct && item.productID === draftProduct.productID ? (
                        <div onClick={e => handleDraftContentClick(e)} className="catalog_content-drafts">
                          {
                            !draftOrders.loaded && draftOrders.loading ? (
                              <div className="catalog_content-drafts-loader">
                                <Spinner />
                              </div>
                            )
                            :
                            (
                              draftOrders.content.map(order => {
                                return (
                                  <div key={order._id} className="catalog_content-drafts-row">
                                    <button onClick={() => addToOrder(order)} className="btn-none catalog_content-drafts-row-wrapper">
                                      <span className="contrast-md-text">
                                        {order.name}
                                      </span>
                                      <div className="btn-none btn-0">
                                        <Plus />
                                      </div>
                                    </button>
                                  </div>
                                )
                              })
                            )
                          }
                        </div>
                      )
                      :
                      undefined
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }

  const handlePagination = (next = false) => {
    paginateCatalogContent(next)
    document.body.scroll(0, 0)
  }

  const handleModalClick = e => {
    if(e.target === modalRef.current){
      closeModal()
    }
  }

  const closeModal = () => {
    setModal(false)
    setSelectedImage(null)
    setDetailProduct(null)
  }

  if(!productsFetched || productsLoading){
    return (
      <LoadingScreen />
    )
  }

  return (
    <div onClick={() => hideDrafts()} className="catalog">
      <div className="catalog_filters">
        <Filters className="catalog" />
      </div>
      <div className="catalog_content">
        <div className="catalog_content-count">
          <span>{t('totalProducts')}: {catalogContent.filteredContent.length}</span>
        </div>
        {getPageContent()}
      </div>
      <Pagination handleClick={next => handlePagination(next)} />
      <div onClick={e => handleModalClick(e)} ref={modalRef} className={`modal fade ${modal ? 'show' : ''}`}>
        <div className="modal-dialog">
          <div className="modal-wrapper detail-modal">
            <div className="modal-header border-bottom">
              <h1 className="modal-title">
                {t('productDetails')}
              </h1>
              <button onClick={() => closeModal()} className="btn-none btn-0 ms-auto">
                <Close />
              </button>
            </div>
            <div className="modal-body">
              {
                //priceListPrice, priceListPriceWithVat, code, groupName, Number(warehouses['1'].totalInStock), manufacturerName, description
                detailProduct ? (
                  <div className="catalog_details">
                    <div className="row g-0">
                      <div className="col-lg-6 catalog_details-images">
                        <div className="catalog_details-images-selected">
                          <img src={selectedImage} />
                        </div>
                        <div className="catalog_details-images-list">
                          {
                            detailProduct.relatedFiles.length === 0 ? (
                              <button onClick={() => setSelectedImage('/media/placeholder.avif')} className="btn-none">
                                <div key={'/media/placeholder.avif'} className={`catalog_details-images-list-item selected`}>
                                  <img src={'/media/placeholder.avif'} />
                                </div>
                              </button>
                            )
                            :
                            (
                              detailProduct.relatedFiles.map(img => {
                                const selected = img.fileURL === selectedImage
                                return (
                                  <button key={img.fileURL} onClick={() => setSelectedImage(img.fileURL)} className="btn-none">
                                    <div key={img.fileURL} className={`catalog_details-images-list-item ${selected ? 'selected' : 'default'}`}>
                                      <img src={img.fileURL} />
                                    </div>
                                  </button>
                                )
                              })
                            )
                          }
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="catalog_details-meta">
                          <h1 className="lg-title">
                            {detailProduct.name ? detailProduct.name : 'Toode millel on nimi'}
                          </h1>
                          <div className="catalog_details-meta-content">

                            <div className="catalog_details-meta-content-row">
                              <label>{t("price")}</label>
                              <label className="contrast-md-text">
                                €{Number(detailProduct.priceListPrice).toFixed(2)}
                              </label>
                            </div>
                            {
                              showTaxRow ? (
                                <div className="catalog_details-meta-content-row">
                                  <label>{t("priceVAT")}</label>
                                  <label className="heavier md-text">
                                    €{Number(detailProduct.priceListPriceWithVat).toFixed(2)}
                                  </label>
                                </div>
                              )
                              :
                              undefined
                            }
                            <div className="catalog_details-meta-content-row">
                              <label>SKU</label>
                              <label className="heavier md-text">
                                {detailProduct.code}
                              </label>
                            </div>
                            <div className="catalog_details-meta-content-row">
                              <label>{t("manufacturer")}</label>
                              <label className="heavier md-text">
                                {detailProduct.brandName}
                              </label>
                            </div>
                            <div className="catalog_details-meta-content-row">
                              <label>{t("category")}</label>
                              <label className="heavier md-text">
                                {detailProduct.groupName}
                              </label>
                            </div>
                            <div className="catalog_details-meta-content-row">
                              <label>{t("availability")}</label>
                              <label className="heavier md-text">
                                {Number(detailProduct.warehouses['1'].totalInStock) > 0 ? t("inStock") : t("backorder")}
                              </label>
                            </div>
                            {
                              detailProduct.pdfFiles && detailProduct.pdfFiles.length ? (
                                <div className="catalog_details-meta-content-row">
                                  <label style={{ flex: 1 }}>{t("specSheet")}</label>
                                  {
                                    detailProduct.pdfFiles.map((file, idx) => {
                                      return (
                                        <a key={file.name + String(idx)} href={file.fileURL} target={"_blank"} className='btn-none btn-0 main-btn' style={{ 
                                          backgroundColor: 'transparent',
                                          padding: 0,
                                          marginRight: '8px',
                                        }}>
                                          <FileIcon />
                                        </a>
                                      )
                                    })
                                  }
                                </div>
                              )
                              :
                              undefined
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    {
                      detailProduct.description ? (
                        <div className="row g-0 catalog_details-description">
                          <div className="col-12">
                            <div className="catalog_details-description-title">
                              <p className="md-title semibold">{t("productDesc")}</p>
                            </div>
                            <div className="catalog_details-description-content">
                              <span className="md-text">{detailProduct.description ? detailProduct.description : detailProduct.descriptionENG}</span>
                            </div>
                          </div>
                        </div>
                      )
                      :
                      undefined
                    }
                  </div>
                )
                :
                undefined
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )

})

export default Catalog