import { observer } from "mobx-react"

const Checkbox = observer(({ label, checked, onChange }) => {

  const handleChange = e => {
    e.stopPropagation()
    e.preventDefault()
    const val = !checked
    onChange(val)
  }

  return (
    <label onClick={(e) => handleChange(e)} className="checkbox-container">
      <input type="checkbox" checked={checked} />
      <span className="checkmark"></span>
      <div className="checkbox-container_text">
        {label}
      </div>
    </label>
  )

})

export default Checkbox