import { useTranslation } from 'react-i18next';
import { ReactComponent as ShareIcon } from '../../svg/share.svg'
import { ReactComponent as FileIcon } from '../../svg/file-check.svg'


const CatalogRow = ({ 
  images, 
  displayVat, 
  brandName, 
  categoryName, 
  showDetails,
  relatedFiles,
  name, 
  priceListPrice,
  priceListPriceWithVat, 
  groupName, 
  productID, 
  showOrderList,
  product,
  code,
  actionClass = "col-md-3",
  metaClass = "col-md-8",
  showDetailButton,
  addToOrder
}) => {

  const { t } = useTranslation()

  const handleAddClick = (e, product) => {
    e.preventDefault()
    e.stopPropagation()
    addToOrder(product)
  }

  const handleDetailClick = productID => {
    showDetails(productID)
  }

  return (
    <div className="catalog-row row g-0">
      <div className='catalog-row_image col-md-1'>
        <img src={relatedFiles.length ? relatedFiles[0].fileURL : '/media/placeholder.avif'} />
      </div>
      <div className={`catalog-row_body pe-3 ${metaClass}`}>
        <div className={`catalog-row_body-top`}>
          <p className='bold elipsis-text'>{name}</p>
          <div style={{ minWidth: 'fit-content' }}>
            <label className='price-label'>{t('price')}: <span className='bold'>{Number(priceListPrice).toFixed(2)}</span></label>
          </div>
        </div>
        <div className='catalog-row_body-bottom'>
          <div className='catalog-row_body-bottom-meta'>
            <label>{t('manufacturer')}: <span className='bold'>{brandName}</span></label>
            <label>{t('type')}: <span className='bold'>{groupName}</span></label>
            <label>SKU: <span className='bold'>{code}</span></label>
          </div>
          {
            displayVat ? (
              <div style={{ minWidth: 'fit-content' }}>
                <label>{t('priceVAT')}: {Number(priceListPriceWithVat).toFixed(2)}</label>
              </div>
            )
            :
            (
              <div />
            )
          }
        </div>
      </div>
      <div className={`catalog-row_actions ${actionClass}`}>
        {
          showDetailButton ? (
            <div className='catalog-row_actions-col'>
              <button onClick={() => handleDetailClick(productID)} className='btn-none semibold btn-0 second-btn'>
                <FileIcon />
                {t('details')}
              </button>
              <button onClick={e => handleAddClick(e)} className='btn-none semibold btn-0 main-btn'>
                <ShareIcon />
                {t('addToOrder')}
              </button>
            </div>
          )
          :
          (
            <div className='catalog-row_actions-col'>
            <button onClick={(e) => handleAddClick(e, productID)} className='btn-none semibold btn-0 main-btn'>
              <ShareIcon />
              {t('addToOrder')}
            </button>
          </div>
          )
        }
      </div>
    </div>
  )

}

export default CatalogRow