import { MobXProviderContext, observer } from "mobx-react";
import { useContext } from "react";

const ErrorPage = observer(() => {

  const { auth } = useContext(MobXProviderContext)

  return (
    <div>
      Page not found
    </div>
  )

})

export default ErrorPage